import * as React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import { getRichText } from '@walltowall/helpers'

import { PageBodyHeroPhrasesFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { FancyHeading } from '../components/FancyHeading'

import AssetTextureYellowDots01 from '../assets/texture-yellow-dots-01.png'
import AssetTextureYellowDots02 from '../assets/texture-yellow-dots-02.png'
import AssetTextureYellowDots03 from '../assets/texture-yellow-dots-03.png'
import AssetTextureYellowDots04 from '../assets/texture-yellow-dots-04.png'
import AssetTextureYellowDots05 from '../assets/texture-yellow-dots-05.png'
import AssetTextureYellowDots06 from '../assets/texture-yellow-dots-06.png'
import AssetTextureYellowDots07 from '../assets/texture-yellow-dots-07.png'
import { HTMLContent } from '../components/HTMLContent'

const backgrounds = [
  AssetTextureYellowDots01,
  AssetTextureYellowDots02,
  AssetTextureYellowDots03,
  AssetTextureYellowDots04,
  AssetTextureYellowDots05,
  AssetTextureYellowDots06,
  AssetTextureYellowDots07,
]

export type PageBodyHeroPhrasesProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyHeroPhrases = ({
  phraseHTMLs = [],
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyHeroPhrasesProps) => {
  const [phraseIndex, setTaglineIndex] = React.useState<number>()
  const phraseHTML =
    phraseIndex === undefined ? undefined : phraseHTMLs[phraseIndex]

  const [backgroundIndex, setBackgroundIndex] = React.useState(0)
  const backgroundUrl = backgrounds[backgroundIndex]

  // Select a random phrase and associated background on mount. We don't want
  // to do this at render-time since we don't want the static build to set a
  // static phrase.
  React.useEffect(() => {
    const index = Math.floor(Math.random() * (phraseHTMLs.length - 1))
    const backgroundIndex = index % backgrounds.length

    setTaglineIndex(index)
    setBackgroundIndex(backgroundIndex)
  }, [phraseHTMLs.length])

  return (
    <>
      <Helmet>
        {backgrounds.map((backgroundUrl) => (
          <link
            key={backgroundUrl}
            rel="preload"
            href={backgroundUrl}
            as="image"
          />
        ))}
      </Helmet>
      <BoundedBox
        as="section"
        id={id}
        nextSharesBg={nextSharesBg}
        nextOverhangs={nextOverhangs}
        previousOverhangs={previousOverhangs}
        ptClassName="pt-10 md:pt-16 lg:pt-23"
        innerMaxWidthClassName="max-w-screen-md"
        className="mx-auto bg-center bg-cover bg-yellow-50 max-w-screen-xl"
        style={{ backgroundImage: `url('${backgroundUrl}')` }}
      >
        <div className="min-h-10rem md:min-h-15rem flex items-center">
          {phraseHTML && (
            <HTMLContent
              html={phraseHTML}
              componentOverrides={{
                p: () => (props) => (
                  <FancyHeading
                    as="p"
                    variant="whiteRed"
                    {...props}
                    className="-mt-1 md:-mt-4"
                  />
                ),
              }}
              className="w-full text-center"
            />
          )}
        </div>
      </BoundedBox>
    </>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyHeroPhrasesFragment,
  typeof mapDataToContext
>) => ({
  phraseHTMLs: data.items?.map((item) => getRichText(item?.phrase)),
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const fragment = graphql`
  fragment PageBodyHeroPhrases on PrismicPageBodyHeroPhrases {
    items {
      phrase {
        text
        html
      }
    }
  }
`

export default PageBodyHeroPhrases
