import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { SkipNavLink } from '@reach/skip-nav'

import { Text } from './Text'
// import { DevPanel } from './DevPanel'

import { useSiteSettings } from '../hooks/useSiteSettings'

export type LayoutProps = {
  children?: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const settings = useSiteSettings()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{settings.siteName}</title>
        <meta name="description" content={settings.siteDescription} />
      </Helmet>
      <SkipNavLink>
        <Text variant="sans-18" className="font-bold">
          Skip to content
        </Text>
      </SkipNavLink>
      <main className="text-debug">{children}</main>
      {/* {process.env.NODE_ENV === 'development' && <DevPanel />} */}
    </>
  )
}
