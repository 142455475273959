import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { getRichText } from '@walltowall/helpers'
import ConditionalWrap from 'conditional-wrap'

import { PageBodyLlPartnersFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'

export type PageBodyPartnersProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyPartners = ({
  textHTML,
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyPartnersProps) => {
  return (
    <BoundedBox
      as="section"
      id={id}
      nextSharesBg={nextSharesBg}
      nextOverhangs={nextOverhangs}
      previousOverhangs={previousOverhangs}
      innerMaxWidthClassName="max-w-screen-lg"
      className="relative max-w-screen-xl mx-auto text-gray-10 bg-teal-40"
    >
      <div className="relative">
        <div className="grid gap-8 lg:gap-10">
          {textHTML && (
            <HTMLContent
              html={textHTML}
              componentOverrides={{
                h1: (Comp) => (props) => (
                  <Comp className="text-yellow-50" {...props} />
                ),
                h2: (Comp) => (props) => (
                  <Comp className="text-white" {...props} />
                ),
                p: (Comp) => (props) => (
                  <Comp variant="sans-16" className="text-white" {...props} />
                ),
              }}
              className="w-full max-w-screen-md mx-auto text-center"
            />
          )}

          <ul className="flex flex-col items-center justify-center -mt-6 -ml-10 md:flex-wrap lg:flex-row">
            {children}
          </ul>
        </div>
      </div>
    </BoundedBox>
  )
}

type PartnerProps = {
  logoImageFluid?: FluidObject
  logoImageAlt?: string
  href?: string
  target?: string
}

const Partner = ({
  logoImageFluid,
  logoImageAlt,
  href,
  target,
}: PartnerProps) =>
  logoImageFluid ? (
    <li className="pt-6 pl-10">
      <ConditionalWrap
        condition={Boolean(href)}
        wrap={(children) => (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <Link href={href!} target={target}>
            {children}
          </Link>
        )}
      >
        <GatsbyImage
          fluid={logoImageFluid}
          alt={logoImageAlt}
          className="w-12rem"
        />
      </ConditionalWrap>
    </li>
  ) : null

PageBodyPartners.Partner = Partner

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyLlPartnersFragment,
  typeof mapDataToContext
>) => ({
  textHTML: getRichText(data.primary?.text),
  children: data.items?.map((item) => (
    <PageBodyPartners.Partner
      key={item?.partner_link?.url}
      logoImageFluid={item?.partner_logo?.fluid}
      logoImageAlt={item?.partner_logo?.alt}
      href={item?.partner_link?.url}
      target={item?.partner_link?.target}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const fragment = graphql`
  fragment PageBodyPartners on PrismicPageBodyPartners {
    primary {
      text {
        text
        html
      }
    }
    items {
      partner_logo {
        alt
        fluid(maxWidth: 200) {
          ...GatsbyPrismicImageFluid
        }
      }
      partner_link {
        url
        target
      }
    }
  }
`

export default PageBodyPartners
