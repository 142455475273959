import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'

import { PageBodyMenuItemsFragment } from '../types.generated'
import { REGISTERED_MARK } from '../constants'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Link } from '../components/Link'
import { Text } from '../components/Text'
import { Anchor } from '../components/Anchor'
import { SuperscriptCharacter } from '../components/SuperscriptCharacter'

export type PageBodyMenuItemsProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyMenuItems = ({
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyMenuItemsProps): JSX.Element => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-lg"
    className="mx-auto bg-white text-gray-10 max-w-screen-xl"
  >
    {children && (
      <ul className="flex flex-wrap items-start justify-center -mt-8 -ml-8 lg:-ml-12 lg:-mt-12">
        {children}
      </ul>
    )}
  </BoundedBox>
)

type MenuItemProps = {
  href: string
  title?: string
  description?: string
  imageFluid?: FluidObject
  imageAlt?: string
}

const MenuItem = ({
  href,
  title,
  description,
  imageFluid,
  imageAlt,
}: MenuItemProps) => (
  <li className="w-full pt-8 pl-8 lg:pl-12 lg:pt-12 md:w-6/12 lg:w-4/12">
    <div className="grid gap-8">
      {imageFluid && (
        <Link href={href}>
          <GatsbyImage
            fluid={imageFluid}
            alt={imageAlt}
            className="block mx-auto max-w-16rem lg:max-w-none"
          />
        </Link>
      )}
      {title && (
        <Text as="h3" variant="sans-40" className="font-extrabold text-center">
          <Anchor
            href={href}
            colorClassName="text-red-50"
            focusColorClassName="focus:text-teal-40"
            hoverColorClassName="hover:text-teal-40"
          >
            <SuperscriptCharacter character={REGISTERED_MARK}>
              {title}
            </SuperscriptCharacter>
          </Anchor>
        </Text>
      )}
      {description && (
        <Text as="p" variant="sans-18" className="text-center">
          {description}
        </Text>
      )}
    </div>
  </li>
)

PageBodyMenuItems.MenuItem = MenuItem

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyMenuItemsFragment, typeof mapDataToContext>) => ({
  children: data.items?.map((item) => {
    const doc = item?.menu_item?.document

    return (
      doc &&
      doc.url && (
        <MenuItem
          key={doc.url}
          href={doc.url}
          title={doc.data?.title?.text}
          description={doc.data?.description?.text}
          imageFluid={doc.data?.featured_image?.fluid}
          imageAlt={doc.data?.featured_image?.alt}
        />
      )
    )
  }) as React.ReactNode | undefined,
})

export const mapDataToContext = () => ({
  bg: 'bg-white',
})

export const fragment = graphql`
  fragment PageBodyMenuItems on PrismicPageBodyMenuItems {
    items {
      menu_item {
        document {
          ... on PrismicMenuItem {
            url
            data {
              title {
                text
              }
              description {
                text
              }
              featured_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageBodyMenuItems
