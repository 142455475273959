import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { buildImgixFluid } from 'gatsby-plugin-imgix'
import { getRichText } from '@walltowall/helpers'

import { PageBodyLlHeroFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'

const hulaGirl1ImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/e81e1252-3b3d-4f0c-9aa9-88a7adefbbf0_luau-and-learn-hula-girl-1.png',
  sourceWidth: 885,
  sourceHeight: 1388,
  args: {
    imgixParams: {
      auto: 'compress,format',
      q: 50,
      w: 885,
      fit: 'max',
    },
  },
})

const hulaGirl2ImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/da9cc75c-8dfc-4e7f-9a60-eed8b4ee88ac_luau-and-learn-hula-girl-2.png',
  sourceWidth: 539,
  sourceHeight: 945,
  args: {
    imgixParams: {
      auto: 'compress,format',
      q: 50,
      w: 539,
      fit: 'max',
    },
  },
})

export type PageBodyLLHeroProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyLLHero = ({
  textHTML,
  introImageFluid,
  introImageAlt,
  secondaryImageFluid,
  secondaryImageAlt,
  backgroundImageFluid,
  backgroundImageAlt,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyLLHeroProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-lg"
    ptClassName={secondaryImageFluid ? 'pt-12 md:pt-17 lg:pt-22' : undefined}
    className="relative mx-auto bg-green-50 max-w-screen-xl"
  >
    {backgroundImageFluid && (
      <div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
        <GatsbyImage
          fluid={backgroundImageFluid}
          alt={backgroundImageAlt}
          imgStyle={{
            objectPosition: secondaryImageFluid
              ? 'bottom center'
              : 'top center',
          }}
          className="w-full h-full"
        />
      </div>
    )}

    <div className="relative grid gap-8 lg:gap-12">
      <div className="w-full mx-auto grid gap-8 lg:gap-12 max-w-screen-md">
        {introImageFluid && (
          <GatsbyImage
            fluid={introImageFluid}
            alt={introImageAlt}
            imgStyle={{ objectFit: 'contain' }}
            className="w-full mx-auto max-w-19rem md:max-w-19rem lg:max-w-36rem"
          />
        )}

        {textHTML && (
          <HTMLContent html={textHTML} className="text-center text-white" />
        )}
      </div>

      {secondaryImageFluid && (
        <div className="relative w-full mx-auto max-w-screen-sm md:max-w-none">
          <div className="absolute left-0 bottom-12 md:bottom-6 transform -translate-x-1/4 lg:-translate-x-1/2 lg:bottom-12">
            <GatsbyImage
              fluid={hulaGirl1ImageFluid}
              alt="Hula girl dancing"
              className="w-23 md:w-12rem lg:w-19rem"
            />
          </div>

          <div className="absolute right-0 bottom-13 md:bottom-6 md:block lg:transform lg:translate-x-1/3 lg:bottom-18">
            <GatsbyImage
              fluid={hulaGirl2ImageFluid}
              alt="Hula girl playing an ukulele"
              className="w-20 md:w-10rem lg:w-17rem"
            />
          </div>

          <GatsbyImage
            fluid={secondaryImageFluid}
            alt={secondaryImageAlt}
            imgStyle={{ objectFit: 'contain' }}
            className="w-full mx-auto max-w-40rem lg:max-w-52rem"
          />
        </div>
      )}
    </div>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyLlHeroFragment, typeof mapDataToContext>) => ({
  textHTML: getRichText(data.primary?.text),
  introImageFluid: data.primary?.intro_image?.fluid,
  introImageAlt: data.primary?.intro_image?.alt,
  secondaryImageFluid: data.primary?.secondary_image?.fluid,
  secondaryImageAlt: data.primary?.secondary_image?.alt,
  backgroundImageFluid: data.primary?.background_image?.fluid,
  backgroundImageAlt: data.primary?.background_image?.alt,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const fragment = graphql`
  fragment PageBodyLLHero on PrismicPageBodyLLHero {
    primary {
      intro_image {
        alt
        fluid(maxWidth: 700) {
          ...GatsbyPrismicImageFluid_noBase64
        }
      }
      text {
        text
        html
      }
      secondary_image {
        alt
        fluid(maxWidth: 700) {
          ...GatsbyPrismicImageFluid
        }
      }
      background_image {
        alt
        fluid(maxWidth: 1000, imgixParams: { q: 80 }) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyLLHero
