type BuildGoogleMapsSearchURLArgs = {
  streetAddress?: string
  state?: string
  city?: string
  zipCode?: string
  country?: string
}

export const buildGoogleMapsSearchURL = ({
  streetAddress,
  state,
  city,
  zipCode,
  country,
}: BuildGoogleMapsSearchURLArgs) =>
  `http://maps.google.com/?q=${encodeURIComponent(
    [streetAddress, state, city, zipCode, country].filter(Boolean).join(' '),
  )}`
