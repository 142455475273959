import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import VisuallyHidden from '@reach/visually-hidden'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import clsx from 'clsx'

import { PageBodyCallToActionBoxesFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { StyledHTMLContent } from '../components/StyledHTMLContent'

export type PageBodyCallToActionBoxesProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyCallToActionBoxes = ({
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
  nextIsFooter,
}: PageBodyCallToActionBoxesProps): JSX.Element => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-70rem"
    ptClassName="pt-10 md:pt-0"
    pbClassName={nextIsFooter ? undefined : 'pb-10 md:pb-0'}
    className="max-w-screen-xl mx-auto bg-white text-gray-10"
  >
    <div className="flex flex-col">
      <div
        className={clsx(
          '-my-10 bg-white md:shadow-xl lg:shadow-2xl md:relative md:z-10 md:-mt-9 lg:-mt-12 md:grid md:grid-cols-3',
          nextIsFooter ? 'md:mb-0' : 'md:-mb-9 lg:-mb-12',
        )}
      >
        {children}
      </div>
    </div>
  </BoundedBox>
)

type CallToActionProps = {
  textHTML?: string
  imageFluid?: FluidObject
  imageAlt?: string
  buttonText?: string
  buttonHref?: string
  buttonTarget?: string
}

const CallToAction = ({
  textHTML,
  imageFluid,
  imageAlt,
  buttonText = 'Learn more',
  buttonHref,
  buttonTarget,
}: CallToActionProps) => (
  <>
    <div className="grid content-center gap-5 py-10 border-t-2 md:px-10 border-gray-80 first:border-t-0 md:border-t-0 md:gap-6 justify-items-center">
      {textHTML && (
        <StyledHTMLContent
          html={textHTML}
          componentOverrides={{
            h1: (Comp) => (props) => (
              <Comp as="h1" className="text-red-50" {...props} />
            ),
          }}
          className="text-center"
        />
      )}

      {buttonHref && (
        <ButtonLink
          variant="yellowSmall"
          href={buttonHref}
          target={buttonTarget}
        >
          <VisuallyHidden>Learn more by visiting {buttonHref}</VisuallyHidden>
          {buttonText}
        </ButtonLink>
      )}
    </div>

    <div className="hidden md:block aspect-w-1 aspect-h-1 bg-red-50 bg-pattern-red bg-w-80rem">
      <div>
        {imageFluid && (
          <GatsbyImage fluid={imageFluid} alt={imageAlt} className="h-full" />
        )}
      </div>
    </div>
  </>
)

PageBodyCallToActionBoxes.CallToAction = CallToAction

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyCallToActionBoxesFragment,
  typeof mapDataToContext
>) => ({
  children: data.items?.map((item) => (
    <CallToAction
      key={item?.text?.text}
      textHTML={getRichText(item?.text)}
      buttonText={item?.button_text?.text}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
      imageFluid={item?.image?.fluid}
      imageAlt={item?.image?.alt}
    />
  )) as React.ReactNode | undefined,
})

export const mapDataToContext = () => ({
  bg: 'bg-white',
  overhangsBottom: [false, true],
  overhangsTop: [false, true],
})

export const fragment = graphql`
  fragment PageBodyCallToActionBoxes on PrismicPageBodyCallToActionBoxes {
    items {
      image {
        alt
        fluid(maxWidth: 500) {
          ...GatsbyPrismicImageFluid
        }
      }
      text {
        text
        html
      }
      button_text {
        text
      }
      button_link {
        url
        target
      }
    }
  }
`

export default PageBodyCallToActionBoxes
