import * as React from 'react'
import { graphql } from 'gatsby'

import { PageBodyHeroNavigationFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StandardGrid } from '../components/StandardGrid'
import { FancyHeading } from '../components/FancyHeading'
import { Text } from '../components/Text'
import { Anchor } from '../components/Anchor'

import AssetTextureYellowDots01 from '../assets/texture-yellow-dots-01.png'
import AssetTextureYellowDots02 from '../assets/texture-yellow-dots-02.png'
import AssetTextureYellowDots03 from '../assets/texture-yellow-dots-03.png'
import AssetTextureYellowDots04 from '../assets/texture-yellow-dots-04.png'
import AssetTextureYellowDots05 from '../assets/texture-yellow-dots-05.png'
import AssetTextureYellowDots06 from '../assets/texture-yellow-dots-06.png'
import AssetTextureYellowDots07 from '../assets/texture-yellow-dots-07.png'

const backgrounds = [
  AssetTextureYellowDots01,
  AssetTextureYellowDots02,
  AssetTextureYellowDots03,
  AssetTextureYellowDots04,
  AssetTextureYellowDots05,
  AssetTextureYellowDots06,
  AssetTextureYellowDots07,
]

export type PageBodyHeroNavigationProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyHeroNavigation = ({
  tagline,
  phrases = [],
  navigationItems = [],
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyHeroNavigationProps) => {
  const [phraseIndex, setTaglineIndex] = React.useState<number>()
  const phrase = phraseIndex === undefined ? undefined : phrases[phraseIndex]

  // Select a random phrase
  React.useEffect(() => {
    const index = Math.floor(Math.random() * (phrases.length - 1))
    setTaglineIndex(index)
  }, [phrases.length])

  const [backgroundIndex, setBackgroundIndex] = React.useState(0)
  const background = backgrounds[backgroundIndex]

  const setBackgroundIndexByLinkIndex = (index: number) => {
    setBackgroundIndex(index % backgrounds.length)
  }

  // Preload all backgrounds
  React.useEffect(() => {
    backgrounds.forEach((url) => {
      const img = new Image()
      img.src = url
    })
  }, [])

  return (
    <BoundedBox
      as="section"
      id={id}
      nextSharesBg={nextSharesBg}
      nextOverhangs={nextOverhangs}
      previousOverhangs={previousOverhangs}
      ptClassName="pt-10 md:pt-16 lg:pt-23"
      className="mx-auto bg-yellow-50 md:bg-white max-w-screen-xl"
    >
      <StandardGrid>
        <div className="col-span-full min-h-27rem md:col-span-6 lg:pl-8 xl:pl-20 md:min-h-36rem">
          <div className="flex items-center h-full">
            {phrase && (
              <>
                <FancyHeading
                  as="p"
                  variant="redWhiteLarge"
                  className="w-full break-words md:hidden max-w-8ch"
                >
                  {phrase}
                </FancyHeading>
                <FancyHeading
                  as="p"
                  variant="redYellowLarge"
                  className="hidden w-full break-words md:block max-w-10ch"
                >
                  {phrase}
                </FancyHeading>
              </>
            )}
          </div>
        </div>

        <BoundedBox
          className="hidden bg-center bg-cover md:block md:bg-yellow-50 md:col-span-6 md:-ml-4 md:-mt-16 md:-mb-13 md:-mr-8 lg:-mt-23 lg:-mb-16 lg:-mr-10 lg:-ml-5 md:min-h-36rem"
          ptClassName="pt-10 md:pt-16 lg:pt-23"
          plClassName="md:pl-8 lg:pl-12 xl:pl-20"
          style={{ backgroundImage: `url('${background}')` }}
        >
          <div className="content-center h-full grid gap-6">
            {tagline && (
              <Text
                as="p"
                variant="sans-16"
                className="pl-1 font-extrabold tracking-widest uppercase text-teal-40"
              >
                {tagline}
              </Text>
            )}
            {navigationItems.length > 0 && (
              <ul className="grid gap-6">
                {navigationItems.map(
                  (item, index) =>
                    item.href && (
                      <li key={item.name}>
                        <Text
                          variant="sans-64"
                          className="font-medium uppercase"
                        >
                          <Anchor
                            href={item.href}
                            colorClassName="text-white"
                            focusColorClassName="focus:text-red-50"
                            hoverColorClassName="hover:text-red-50"
                            onFocus={() => setBackgroundIndexByLinkIndex(index)}
                            onMouseOver={() =>
                              setBackgroundIndexByLinkIndex(index)
                            }
                          >
                            {item.name}
                          </Anchor>
                        </Text>
                      </li>
                    ),
                )}
              </ul>
            )}
          </div>
        </BoundedBox>
      </StandardGrid>
    </BoundedBox>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyHeroNavigationFragment,
  typeof mapDataToContext
>) => {
  const navigationDoc = data.primary?.navigation?.document

  return {
    tagline: data.primary?.tagline?.text,
    phrases: data.items?.map((item) => item?.phrase?.text),
    navigationItems: navigationDoc?.data?.main?.map((item) => ({
      name: item?.primary?.name,
      href: item?.primary?.link?.url,
      target: item?.primary?.link?.target,
    })),
  }
}

export const mapDataToContext = () => ({
  bg: ['bg-yellow-50', Symbol()],
})

export const fragment = graphql`
  fragment PageBodyHeroNavigation on PrismicPageBodyHeroNavigation {
    primary {
      tagline {
        text
      }
      navigation {
        document {
          ... on PrismicNavigation {
            data {
              main {
                ... on PrismicNavigationMainNavItem {
                  primary {
                    name
                    link {
                      url
                      target
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    items {
      phrase {
        text
      }
    }
  }
`

export default PageBodyHeroNavigation
