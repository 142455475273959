import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import clsx from 'clsx'

import { PageBodyNutritionFactsFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { Text } from '../components/Text'

type NutritionFactProps = {
  label: string
  value?: string
}

const NutritionFact = ({ label, value }: NutritionFactProps) => (
  <div
    className={clsx(
      'items-baseline justify-between py-5 grid gap-8 grid-flow-col border-b-1 last:border-b-0 border-yellow-50',
      !value && 'text-gray-60',
    )}
  >
    <dt>
      <Text variant="sans-18" className="whitespace-nowrap">
        {label}
      </Text>
    </dt>
    <dd>
      <Text
        variant="sans-18"
        className={clsx('whitespace-nowrap', value && 'font-bold tabular-nums')}
      >
        {value ?? 'Unavailable'}
      </Text>
    </dd>
  </div>
)

export type PageBodyNutritionFactsProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyNutritionFacts = ({
  calories,
  caloriesFromFat,
  totalFat,
  saturatedFat,
  cholesterol,
  sodium,
  totalCarbohydrates,
  dietaryFiber,
  sugar,
  protein,
  disclaimerHTML,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyNutritionFactsProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-lg"
    className="mx-auto bg-white text-gray-10 max-w-screen-xl"
  >
    <div className="grid gap-7 md:gap-8">
      <dl className="grid md:grid-cols-2 md:gap-8">
        <div className="border-t-3 border-yellow-50 border-b-1 md:border-b-3">
          <NutritionFact label="Calories" value={calories} />
          <NutritionFact label="Calores From Fat" value={caloriesFromFat} />
          <NutritionFact label="Total Fat" value={totalFat} />
          <NutritionFact label="Saturated Fat" value={saturatedFat} />
          <NutritionFact label="Cholesterol" value={cholesterol} />
        </div>
        <div className="border-b-3 border-yellow-50 md:border-t-3">
          <NutritionFact label="Sodium" value={sodium} />
          <NutritionFact
            label="Total Carbohydrates"
            value={totalCarbohydrates}
          />
          <NutritionFact label="Dietary Fiber" value={dietaryFiber} />
          <NutritionFact label="Sugar" value={sugar} />
          <NutritionFact label="Protein" value={protein} />
        </div>
      </dl>
      {disclaimerHTML && (
        <StyledHTMLContent
          html={disclaimerHTML}
          componentOverrides={{
            p: (Comp) => (props) => <Comp variant="sans-14" {...props} />,
            li: (Comp) => (props) => <Comp variant="sans-14" {...props} />,
          }}
        />
      )}
    </div>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyNutritionFactsFragment,
  typeof mapDataToContext
>) => ({
  calories: data.primary?.calories?.text,
  caloriesFromFat: data.primary?.calories_from_fat?.text,
  totalFat: data.primary?.total_fat?.text,
  saturatedFat: data.primary?.saturated_fat?.text,
  cholesterol: data.primary?.cholesterol?.text,
  sodium: data.primary?.sodium?.text,
  totalCarbohydrates: data.primary?.total_carbohydrates?.text,
  dietaryFiber: data.primary?.dietary_fiber?.text,
  sugar: data.primary?.sugar?.text,
  protein: data.primary?.protein?.text,
  disclaimerHTML: getRichText(data?.primary?.disclaimer),
})

export const mapDataToContext = () => ({
  bg: 'bg-white',
})

export const fragment = graphql`
  fragment PageBodyNutritionFacts on PrismicPageBodyNutritionFacts {
    primary {
      calories {
        text
      }
      calories_from_fat {
        text
      }
      total_fat {
        text
      }
      saturated_fat {
        text
      }
      cholesterol {
        text
      }
      sodium {
        text
      }
      total_carbohydrates {
        text
      }
      dietary_fiber {
        text
      }
      sugar {
        text
      }
      protein {
        text
      }
      disclaimer {
        text
        html
      }
    }
  }
`

export default PageBodyNutritionFacts
