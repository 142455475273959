import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { PageBodyImageFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'

export type PageBodyImageProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyImage = ({
  captionHTML,
  imageFluid,
  imageAlt,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyImageProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-lg"
    className="mx-auto bg-white text-gray-10 max-w-screen-xl"
  >
    <div className="grid gap-4 md:gap-6 lg:gap-8 justify-items-center">
      {imageFluid && (
        <GatsbyImage fluid={imageFluid} alt={imageAlt} className="w-full" />
      )}
      {captionHTML && (
        <HTMLContent
          html={captionHTML}
          componentOverrides={{
            p: (Comp) => (props) => <Comp variant="sans-16" {...props} />,
          }}
          className="w-full mx-auto text-center text-gray-60 max-w-screen-md"
        />
      )}
    </div>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyImageFragment, typeof mapDataToContext>) => ({
  captionHTML: getRichText(data.primary?.caption),
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
})

export const mapDataToContext = () => ({
  bg: 'bg-white',
})

export const fragment = graphql`
  fragment PageBodyImage on PrismicPageBodyImage {
    primary {
      caption {
        text
        html
      }
      image {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyImage
