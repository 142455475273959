import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { Dialog } from '@reach/dialog'
import { buildImgixFluid } from 'gatsby-plugin-imgix'
import { getRichText } from '@walltowall/helpers'
import clsx from 'clsx'

import { PageBodyLlTextFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'

const grassImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/40d6a35e-baee-43e3-a7c4-a3aa7f7c8070_texture-grass.png',
  sourceWidth: 270,
  sourceHeight: 350,
  args: {
    imgixParams: {
      auto: 'compress,format',
      q: 50,
      w: 270,
      fit: 'max',
    },
  },
})

export type PageBodyLLTextProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyLLText = ({
  textHTML,
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyLLTextProps) => {
  const hasChildren = React.Children.count(children) > 0

  return (
    <BoundedBox
      as="section"
      id={id}
      nextSharesBg={nextSharesBg}
      nextOverhangs={nextOverhangs}
      previousOverhangs={previousOverhangs}
      innerMaxWidthClassName="max-w-screen-lg"
      ptClassName="pt-12 md:pt-17 lg:pt-22"
      pbClassName="pb-12 md:pb-17 lg:pb-22"
      className="relative mx-auto overflow-hidden bg-repeat-y bg-w-full white text-green-20 max-w-screen-xl bg-pattern-paper"
    >
      <div className="absolute top-0 left-0 hidden md:block">
        <GatsbyImage
          fluid={grassImageFluid}
          alt=""
          className="w-17rem transform -rotate-12 -translate-x-1/3 -translate-y-1/4"
        />
      </div>

      <div className="absolute top-0 right-0 hidden md:block">
        <GatsbyImage
          fluid={grassImageFluid}
          alt=""
          className="w-17rem transform rotate-45 translate-x-1/3 -translate-y-1/4"
        />
      </div>

      <div className="absolute bottom-0 left-0 hidden md:block">
        <GatsbyImage
          fluid={grassImageFluid}
          alt=""
          className="w-17rem transform -rotate-135 -translate-x-1/3 translate-y-1/4"
        />
      </div>

      <div className="absolute bottom-0 right-0 hidden md:block">
        <GatsbyImage
          fluid={grassImageFluid}
          alt=""
          className="w-17rem transform rotate-135 translate-x-1/3 translate-y-1/4"
        />
      </div>

      <div className="relative grid gap-8 lg:gap-16">
        {textHTML && (
          <HTMLContent
            html={textHTML}
            componentOverrides={{
              h1: (Comp) => (props) => (
                <Comp className="text-orange-60" {...props} />
              ),
              a: (Comp) => (props) => (
                <Comp
                  colorClassName="text-orange-60"
                  hoverColorClassName="hover:text-red-30"
                  focusColorClassName="focus:text-red-30"
                  {...props}
                />
              ),
              span: (Comp) => ({ class: labelName, ...props }) => (
                <Comp
                  className={clsx(labelName === 'Orange' && 'text-orange-60')}
                  {...props}
                />
              ),
            }}
            className="w-full mx-auto text-center max-w-screen-md"
          />
        )}

        {hasChildren && (
          <ul className="flex flex-col items-center justify-center -mt-6 -ml-10 md:flex-wrap md:flex-row">
            {children}
          </ul>
        )}
      </div>
    </BoundedBox>
  )
}

type ImageProps = {
  imageFluid?: FluidObject
  imageAlt?: string
}

const Image = ({ imageFluid, imageAlt }: ImageProps) => {
  const [isZoomed, setIsZoomed] = React.useState(false)
  const zoomIn = () => setIsZoomed(true)
  const zoomOut = () => setIsZoomed(false)

  return imageFluid ? (
    <>
      <li className="w-full pt-6 pl-10 max-w-24rem md:max-w-none md:w-4/12">
        <button onClick={zoomIn} className="block w-full">
          <GatsbyImage
            fluid={imageFluid}
            alt={imageAlt}
            className={clsx(
              'border-8 border-white shadow-lg transition',
              isZoomed && 'opacity-0',
            )}
          />
        </button>
      </li>
      <Dialog
        as={BoundedBox}
        isOpen={isZoomed}
        onDismiss={zoomOut}
        aria-label={imageAlt || `Zoomed in image`}
        className="h-full outline-none pointer-events-none"
        innerMaxWidthClassName="max-w-screen-sm"
      >
        <div className="flex items-center justify-center min-h-full">
          <div className="w-full pointer-events-auto">
            <GatsbyImage
              fluid={imageFluid}
              alt={imageAlt}
              className={clsx('border-8 border-white shadow-lg transition')}
            />
          </div>
        </div>
      </Dialog>
    </>
  ) : null
}

PageBodyLLText.Image = Image

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyLlTextFragment, typeof mapDataToContext>) => ({
  textHTML: getRichText(data.primary?.text),
  children: data.items?.map((item) => (
    <PageBodyLLText.Image
      key={item?.image?.url}
      imageFluid={item?.image?.fluid}
      imageAlt={item?.image?.alt}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const fragment = graphql`
  fragment PageBodyLLText on PrismicPageBodyLLText {
    primary {
      text {
        text
        html
      }
    }
    items {
      image {
        url
        alt
        fluid(maxWidth: 500) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyLLText
