import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import HTMLRenderer, { HTMLRendererProps } from 'react-html-renderer'
import clsx from 'clsx'

import { Anchor } from './Anchor'
import { Text } from './Text'

const defaultElement = 'div'

const baseHeadingClassName =
  'mt-8 md:mt-9 lg:mt-10 mb-7 md:mb-8 first:mt-0 last:mb-0'
const baseTextClassName = 'mb-6 md:mb-6 lg:mb-8 last:mb-0'

/**
 * Custom styles can be added to Prismic Rich Text fields using labels. Styles
 * defined here will be applied using the `span` component defined in
 * `components` below.
 *
 * @see https://user-guides.prismic.io/en/articles/1943308-add-custom-styles-to-rich-text
 */
const labelClassNames: Record<string, string> = {}

const components: React.ComponentProps<typeof HTMLRenderer>['components'] = {
  h1: (props) => (
    <Text
      as="h3"
      variant="sans-35-40"
      {...props}
      className={clsx(
        baseHeadingClassName,
        'font-extrabold',
        props.className,
        props.class,
      )}
    />
  ),
  h2: (props) => (
    <Text
      as="h5"
      variant="sans-24"
      {...props}
      className={clsx(
        baseHeadingClassName,
        'font-bold',
        props.className,
        props.class,
      )}
    />
  ),
  h3: (props) => (
    <Text
      as="h6"
      variant="sans-18"
      {...props}
      className={clsx(
        baseHeadingClassName,
        'font-bold uppercase tracking-wide',
        props.className,
        props.class,
      )}
    />
  ),
  h4: (props) => (
    <Text
      as="h6"
      variant="sans-18"
      {...props}
      className={clsx(
        baseHeadingClassName,
        'font-bold',
        props.className,
        props.class,
      )}
    />
  ),
  h5: (props) => (
    <Text
      as="h6"
      variant="sans-14"
      {...props}
      className={clsx(
        baseHeadingClassName,
        'font-bold uppercase tracking-wide',
        props.className,
        props.class,
      )}
    />
  ),
  h6: (props) => (
    <Text
      as="h6"
      variant="sans-14"
      {...props}
      className={clsx(
        baseHeadingClassName,
        'font-bold',
        props.className,
        props.class,
      )}
    />
  ),
  p: (props) => (
    <Text
      as="p"
      variant="sans-18"
      {...props}
      className={clsx(baseTextClassName, props.className, props.class)}
    />
  ),
  ul: (props) => (
    <ul
      {...props}
      className={clsx(
        baseTextClassName,
        'pl-7 md:pl-8 list-disc',
        props.className,
        props.class,
      )}
    />
  ),
  ol: (props) => (
    <ol
      {...props}
      className={clsx(
        baseTextClassName,
        'pl-7 md:pl-8 list-decimal',
        props.className,
        props.class,
      )}
    />
  ),
  li: (props) => (
    <Text
      as="li"
      variant="sans-18"
      {...props}
      className={clsx('mb-4', props.className, props.class)}
    />
  ),
  a: ({ href, ...props }) => (
    <Anchor
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      href={href!}
      {...props}
    />
  ),
  strong: (props) => (
    <strong
      {...props}
      className={clsx('font-bold', props.className, props.class)}
    />
  ),
  span: ({ class: labelName, ...props }) => (
    <span
      {...props}
      className={clsx(labelClassNames[labelName], props.className)}
    />
  ),
}

type HTMLContentOwnProps = {
  html?: HTMLRendererProps['html']
  componentOverrides?: HTMLRendererProps['componentOverrides']
}

export type HTMLContentProps<
  T extends React.ElementType = typeof defaultElement
> = PolymorphicPropsWithoutRef<HTMLContentOwnProps, T>

export const HTMLContent = <
  E extends React.ElementType = typeof defaultElement
>({
  as,
  html,
  componentOverrides,
  ...props
}: HTMLContentProps<E>) => {
  const Element: React.ElementType = as ?? defaultElement

  return (
    <Element {...props}>
      <HTMLRenderer
        html={html}
        components={components}
        componentOverrides={componentOverrides}
      />
    </Element>
  )
}
