/**
 * Note: This Slice is used only within the project and is not made available
 * to editors in Prismic.
 */

import * as React from 'react'
import { graphql } from 'gatsby'
import clsx from 'clsx'

import { PageBodyLocationStaticMapFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { buildGoogleMapsSearchURL } from '../lib/buildGoogleMapsSearchURL'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'

export type PageBodyLocationStaticMapProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyLocationStaticMap = ({
  googleStaticMapURL,
  streetAddress,
  city,
  state,
  zipCode,
  country,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
  nextIsFooter,
}: PageBodyLocationStaticMapProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    pbClassName={nextIsFooter ? undefined : 'pb-0'}
    innerMaxWidthClassName="max-w-70rem"
    className="mx-auto text-white bg-teal-40 max-w-screen-xl bg-center bg-pattern-teal relative flex"
  >
    <div
      className={clsx(
        'shadow-lg md:shadow-xl lg:shadow-2xl relative bg-teal-20',
        !nextIsFooter && 'z-10 -mb-5 md:-mb-9 lg:-mb-12',
      )}
    >
      <div className="aspect-w-2 aspect-h-1">
        <div>
          {googleStaticMapURL && (
            <img
              src={googleStaticMapURL}
              className="block object-cover w-full h-full"
              alt="Google Map centered on the location"
            />
          )}
        </div>
      </div>

      <ButtonLink
        variant="yellowSmall"
        href={buildGoogleMapsSearchURL({
          streetAddress,
          state,
          city,
          zipCode,
          country,
        })}
        className="absolute right-4 bottom-4 md:bottom-8 md:right-8"
      >
        Open in Google Maps
      </ButtonLink>
    </div>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  NonNullable<PageBodyLocationStaticMapFragment>,
  typeof mapDataToContext
>) => ({
  googleStaticMapURL: data._ON_BUILD_ONLY_google_static_map_url,
  streetAddress: data.data?.street_address?.text,
  city: data.data?.city?.text,
  state: data.data?.state?.text,
  zipCode: data.data?.zip_code?.text,
  country: data.data?.country?.text,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
  overhangsBottom: true,
})

export const fragment = graphql`
  # Note: This isn't a slice fragment since this slice does not exist in Prismic.
  fragment PageBodyLocationStaticMap on PrismicLocation {
    _ON_BUILD_ONLY_google_static_map_url
    data {
      street_address {
        text
      }
      city {
        text
      }
      state {
        text
      }
      zip_code {
        text
      }
      country {
        text
      }
    }
  }
`

export default PageBodyLocationStaticMap
