import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import VisuallyHidden from '@reach/visually-hidden'
import clsx from 'clsx'

import { buildGoogleMapsDirectionsURL } from '../lib/buildGoogleMapsDirectionsURL'

import { Text } from './Text'
import { Anchor } from './Anchor'
import { Address } from './Address'
import { Icon, IconProps } from './Icon'
import { ButtonLink } from './ButtonLink'
import { HTMLContent } from './HTMLContent'
import { HoursList } from './HoursList'

type OnlineOrderLinkProps = {
  href: string
  iconName: IconProps['name']
  label: string
}

const OnlineOrderLink = ({ iconName, href, label }: OnlineOrderLinkProps) => (
  <li className="pt-3 pl-3">
    <VisuallyHidden>{label}</VisuallyHidden>
    <div className="flex items-center justify-center w-6 h-6">
      <Anchor href={href}>
        <Icon name={iconName} className="w-full h-full" />
      </Anchor>
    </div>
  </li>
)

type IconTextLinkProps = {
  href: string
  iconName: IconProps['name']
  label: string
  children?: string
}

const IconTextLink = ({
  iconName,
  href,
  label,
  children,
}: IconTextLinkProps) => (
  <div>
    <dt className="sr-only">{label}</dt>
    <dd className="grid items-center justify-start grid-flow-col gap-2">
      <Icon name={iconName} className="w-5 h-5 text-red-50" />
      <Text variant="sans-18">
        <Anchor href={href} target="_blank">
          {children}
        </Anchor>
      </Text>
    </dd>
  </div>
)

type IconLinkProps = {
  href: string
  iconName: IconProps['name']
  label: string
}

const IconLink = ({ iconName, href, label }: IconLinkProps) => (
  <li className="pt-4 pl-4">
    <Anchor
      href={href}
      colorClassName="text-red-50"
      hoverColorClassName="hover:text-teal-40"
      focusColorClassName="focus:text-teal-40"
    >
      <Icon name={iconName} className="w-7 h-7" />
      <span className="sr-only">{label}</span>
    </Anchor>
  </li>
)

type FeatureProps = {
  enabled?: boolean
  iconName: IconProps['name']
  enabledLabel: string
  disabledLabel: string
}

const Feature = ({
  enabled = false,
  iconName,
  enabledLabel,
  disabledLabel,
}: FeatureProps) => (
  <li className="pt-3 pl-3">
    <VisuallyHidden>{enabled ? enabledLabel : disabledLabel}</VisuallyHidden>
    <div
      className="flex items-center justify-center w-4 h-4"
      title={enabled ? enabledLabel : disabledLabel}
    >
      <Icon
        name={iconName}
        className={clsx(
          'w-full h-full',
          enabled ? 'text-gray-10' : 'text-gray-80',
        )}
      />
    </div>
  </li>
)

const defaultElement = 'div'

type LocationCardOwnProps = {
  href: string

  title?: string
  type?: string
  phoneNumber?: string
  websiteHref?: string
  specialNoteHTML?: string
  menuHref?: string
  locationDetails?: string
  streetAddress?: string
  city?: string
  state?: string
  zipCode?: string
  country?: string

  distance?: string

  ubereatsHref?: string
  doordashHref?: string
  postmatesHref?: string
  biteSquadHref?: string
  grubhubHref?: string
  loyaltyplantHref?: string

  mondayHours?: string
  tuesdayHours?: string
  wednesdayHours?: string
  thursdayHours?: string
  fridayHours?: string
  saturdayHours?: string
  sundayHours?: string

  facebookHandle?: string
  instagramHandle?: string
  twitterHandle?: string

  acceptsGiftCards?: boolean
  providesOnlineOrdering?: boolean
  providesDelivery?: boolean
  acceptsCashOnly?: boolean

  mapLabel?: string
}

export type LocationCardProps<
  T extends React.ElementType = typeof defaultElement
> = PolymorphicPropsWithoutRef<LocationCardOwnProps, T>

export const LocationCard = <
  T extends React.ElementType = typeof defaultElement
>({
  href,

  title,
  type,
  phoneNumber,
  websiteHref,
  specialNoteHTML,
  menuHref,
  locationDetails,
  streetAddress,
  city,
  state,
  zipCode,
  country,

  distance,

  ubereatsHref,
  doordashHref,
  postmatesHref,
  biteSquadHref,
  grubhubHref,
  loyaltyplantHref,

  facebookHandle,
  instagramHandle,
  twitterHandle,

  mondayHours,
  tuesdayHours,
  wednesdayHours,
  thursdayHours,
  fridayHours,
  saturdayHours,
  sundayHours,

  acceptsGiftCards,
  providesOnlineOrdering,
  providesDelivery,
  acceptsCashOnly,

  mapLabel,

  as,
  className,
  ...restProps
}: LocationCardProps<T>) => {
  const Element: React.ElementType = as ?? defaultElement
  const directionsHref = buildGoogleMapsDirectionsURL({
    streetAddress,
    state,
    city,
    zipCode,
    country,
  })

  return (
    <Element
      className={clsx(
        'bg-white shadow-md ring-1 ring-black ring-opacity-5 text-gray-10 p-6 md:p-7',
        className,
      )}
      {...restProps}
    >
      <div className="grid gap-6">
        {mapLabel && (
          <div className="p-3 -mt-6 text-white transform -translate-y-px justify-self-start md:-mt-7 bg-red-50">
            <Text
              variant="sans-14"
              className="font-extrabold tracking-wider text-center uppercase"
            >
              {mapLabel}
            </Text>
          </div>
        )}

        <div className="grid gap-4">
          {type === 'Mixplate' && (
            <div className="p-2 justify-self-start bg-yellow-50 text-red-50">
              <Text
                as="span"
                variant="sans-14"
                className="block font-extrabold tracking-wide uppercase"
              >
                L&L Hawaiian Mixplate
              </Text>
            </div>
          )}

          {title && (
            <Text variant="sans-24-30" className="font-bold">
              <Anchor href={href}>{title}</Anchor>
            </Text>
          )}

          <ul className="flex flex-wrap -mt-3 -ml-3">
            <Feature
              iconName="locationCardOnlineOrdering"
              enabled={providesOnlineOrdering}
              enabledLabel="Provides online ordering"
              disabledLabel="Does not provide online ordering"
            />
            <Feature
              iconName="locationCardDelivery"
              enabled={providesDelivery}
              enabledLabel="Provides delivery"
              disabledLabel="Does not provide delivery"
            />
            <Feature
              iconName="locationCardGiftCard"
              enabled={acceptsGiftCards}
              enabledLabel="Accepts gift cards"
              disabledLabel="Does not accept gift cards"
            />
          </ul>
        </div>

        <div className="grid items-start gap-5 md:grid-cols-2">
          <div className="grid gap-5">
            {locationDetails && (
              <Text as="p" variant="sans-18">
                {locationDetails}
              </Text>
            )}

            <Text as="p" variant="sans-18">
              <Address
                streetAddress={streetAddress}
                city={city}
                state={state}
                zipCode={zipCode}
                country={country}
              />
            </Text>

            {distance && (
              <div className="p-2 bg-gray-95 justify-self-start">
                <Text variant="sans-15" className="font-bold">
                  {distance}
                </Text>
              </div>
            )}

            <dl className="grid gap-3">
              {phoneNumber && (
                <IconTextLink
                  label="Phone Number"
                  iconName="phone"
                  href={`tel:${phoneNumber}`}
                >
                  {phoneNumber}
                </IconTextLink>
              )}
              {websiteHref && (
                <IconTextLink
                  label="Website"
                  iconName="website"
                  href={websiteHref}
                >
                  Open website
                </IconTextLink>
              )}
              <IconTextLink
                label="Directions"
                iconName="directions"
                href={directionsHref}
              >
                Directions
              </IconTextLink>
            </dl>

            {(facebookHandle || instagramHandle || twitterHandle) && (
              <ul className="flex flex-wrap -mt-4 -ml-4">
                {facebookHandle && (
                  <IconLink
                    href={`https://www.facebook.com/${facebookHandle}`}
                    iconName="facebookCircle"
                    label="Restaurant's Facebook page"
                  />
                )}
                {instagramHandle && (
                  <IconLink
                    href={`https://www.instagram.com/${instagramHandle}`}
                    iconName="instagramCircle"
                    label="Restaurant's Instagram page"
                  />
                )}
                {twitterHandle && (
                  <IconLink
                    href={`https://www.twitter.com/${twitterHandle}`}
                    iconName="twitterCircle"
                    label="Restaurant's Twitter page"
                  />
                )}
              </ul>
            )}

            {(loyaltyplantHref || menuHref) && (
              <ul className="flex flex-wrap -mt-4 -ml-4">
                {loyaltyplantHref && (
                  <li className="pt-4 pl-4">
                    <ButtonLink
                      variant="redSmall"
                      href={loyaltyplantHref}
                      target="_blank"
                    >
                      Order Now
                    </ButtonLink>
                  </li>
                )}
                {menuHref && (
                  <li className="pt-4 pl-4">
                    <ButtonLink
                      variant="redSmall"
                      href={menuHref}
                      target="_blank"
                    >
                      Menu
                    </ButtonLink>
                  </li>
                )}
              </ul>
            )}

            {(ubereatsHref ||
              doordashHref ||
              postmatesHref ||
              biteSquadHref ||
              grubhubHref) && (
              <ul className="flex flex-wrap -mt-3 -ml-3">
                {ubereatsHref && (
                  <OnlineOrderLink
                    iconName="ubereats"
                    href={ubereatsHref}
                    label="UberEats"
                  />
                )}
                {doordashHref && (
                  <OnlineOrderLink
                    iconName="doordash"
                    href={doordashHref}
                    label="DoorDash"
                  />
                )}
                {postmatesHref && (
                  <OnlineOrderLink
                    iconName="postmates"
                    href={postmatesHref}
                    label="Postmates"
                  />
                )}
                {biteSquadHref && (
                  <OnlineOrderLink
                    iconName="biteSquad"
                    href={biteSquadHref}
                    label="Bite Squad"
                  />
                )}
                {grubhubHref && (
                  <OnlineOrderLink
                    iconName="grubhub"
                    href={grubhubHref}
                    label="Grubhub"
                  />
                )}
              </ul>
            )}
          </div>

          <div className="grid gap-5">
            <Text
              as="span"
              variant="sans-18"
              className="font-bold tracking-wide uppercase"
            >
              Hours
            </Text>

            {specialNoteHTML && <HTMLContent html={specialNoteHTML} />}

            <HoursList
              monday={mondayHours}
              tuesday={tuesdayHours}
              wednesday={wednesdayHours}
              thursday={thursdayHours}
              friday={fridayHours}
              saturday={saturdayHours}
              sunday={sundayHours}
            />
          </div>
        </div>
      </div>
    </Element>
  )
}
