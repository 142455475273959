import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { buildImgixFluid } from 'gatsby-plugin-imgix'
import clsx from 'clsx'

import { PageBodyHeroFloatingImageFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'

const backgroundTextureImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/d69a6084-6c6a-4dc5-a39f-6e5d97d5cbac_texture-dots-surfboards.png',
  sourceWidth: 1440,
  sourceHeight: 600,
  args: {
    imgixParams: {
      auto: 'compress,format',
      q: 50,
      w: 1200,
      fit: 'max',
    },
  },
})

const starburstImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/ff7120cf-7f45-45c1-86ce-517eac8df1ca_texture-starburst.png',
  sourceWidth: 1508,
  sourceHeight: 1488,
  args: {
    imgixParams: {
      auto: 'compress,format',
      q: 50,
      w: 1200,
      fit: 'max',
    },
  },
})

export type PageBodyHeroFloatingImageProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyHeroFloatingImage = ({
  mobileImageFluid,
  imageFluid,
  imageAlt,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
  nextIsFooter,
}: PageBodyHeroFloatingImageProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-70rem"
    plClassName="pl-4 md:pl-8 lg:pl-10"
    prClassName="pr-4 md:pr-8 lg:pr-10"
    pbClassName={nextIsFooter ? undefined : 'pb-0'}
    className="relative flex max-w-screen-xl mx-auto bg-yellow-50"
  >
    <div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
      <GatsbyImage
        fluid={backgroundTextureImageFluid}
        alt=""
        className="w-full h-full"
      />
    </div>
    <div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
      <div className="max-w-screen-lg mx-auto">
        <div className="relative aspect-w-4 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          <div>
            <div className="absolute top-0 left-0 transform w-24rem md:w-36rem lg:w-46rem -translate-x-1/3 -translate-y-1/3 lg:-translate-x-1/2">
              <GatsbyImage fluid={starburstImageFluid} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className={clsx(
        'relative',
        !nextIsFooter && 'z-10 -mb-5 md:-mb-9 lg:-mb-12',
      )}
    >
      <div className="shadow-lg aspect-w-4 aspect-h-3 md:aspect-w-2 md:aspect-h-1 md:shadow-xl lg:shadow-2xl bg-yellow-40">
        <div>
          {imageFluid && (
            <GatsbyImage
              fluid={imageFluid}
              alt={imageAlt}
              className={clsx('h-full', mobileImageFluid && 'hidden md:block')}
            />
          )}
          {mobileImageFluid && (
            <GatsbyImage
              fluid={mobileImageFluid}
              alt={imageAlt}
              className="h-full md:hidden"
            />
          )}
        </div>
      </div>
    </div>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyHeroFloatingImageFragment,
  typeof mapDataToContext
>) => ({
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
  mobileImageFluid: data.primary?.image?.thumbnails?.Mobile?.fluid,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
  overhangsBottom: true,
})

export const fragment = graphql`
  fragment PageBodyHeroFloatingImage on PrismicPageBodyHeroFloatingImage {
    primary {
      image {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
        thumbnails {
          Mobile {
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`

export default PageBodyHeroFloatingImage
