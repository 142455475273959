import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { PageBodyFeaturedImageFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'

export type PageBodyFeaturedImageProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyFeaturedImage = ({
  textHTML,
  imageFluid,
  imageAlt,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyFeaturedImageProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-lg"
    ptClassName="pt-0"
    className="mx-auto text-white max-w-screen-xl bg-teal-40"
  >
    <div className="grid gap-7 md:gap-8 lg:gap-10">
      <div className="relative z-10 -mt-5 md:-mt-9 lg:-mt-12 shadow-lg md:shadow-xl lg:shadow-2xl">
        <div className="aspect-w-4 aspect-h-3 md:aspect-w-9 md:aspect-h-5 bg-yellow-50">
          <div>
            {imageFluid && (
              <GatsbyImage
                fluid={imageFluid}
                alt={imageAlt}
                className="w-full h-full"
              />
            )}
          </div>
        </div>
      </div>

      {textHTML && (
        <HTMLContent
          html={textHTML}
          componentOverrides={{
            h1: (Comp) => (props) => (
              <Comp
                as="h1"
                className="tracking-wide uppercase text-yellow-50"
                {...props}
              />
            ),
            h2: (Comp) => (props) => (
              <Comp as="p" className="tracking-wide uppercase" {...props} />
            ),
          }}
          className="mx-auto text-center max-w-screen-md"
        />
      )}
    </div>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyFeaturedImageFragment,
  typeof mapDataToContext
>) => ({
  textHTML: getRichText(data?.primary?.text),
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
})

export const mapDataToContext = () => ({
  bg: 'bg-teal-40',
  overhangsTop: true,
})

export const fragment = graphql`
  fragment PageBodyFeaturedImage on PrismicPageBodyFeaturedImage {
    primary {
      image {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
      text {
        text
        html
      }
    }
  }
`

export default PageBodyFeaturedImage
