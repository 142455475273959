import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { PageBodyImageFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

export type PageBodyImageProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyFullWidthImage = ({
  imageFluid,
  imageAlt,
  id,
}: PageBodyImageProps) => {
  return (
    <section id={id} className="bg-white text-gray-10">
      {imageFluid && (
        <GatsbyImage fluid={imageFluid} alt={imageAlt} className="w-full" />
      )}
    </section>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyImageFragment, typeof mapDataToContext>) => ({
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
})

export const mapDataToContext = () => ({
  bg: 'bg-white',
})

export const fragment = graphql`
  fragment PageBodyFullWidthImage on PrismicPageBodyFullWidthImage {
    primary {
      image {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyFullWidthImage
