/**
 * Note: This Slice is used only within the project and is not made available
 * to editors in Prismic.
 */

import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText, undefIfEmpty } from '@walltowall/helpers'
import ConditionalWrap from 'conditional-wrap'

import { PageBodyLocationDetailsFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { buildGoogleMapsDirectionsURL } from '../lib/buildGoogleMapsDirectionsURL'
import { toTimeRange } from '../lib/toTimeRange'
import { PageTemplateEnhancerProps } from '../templates/page'

import { Address } from '../components/Address'
import { Anchor } from '../components/Anchor'
import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { HTMLContent } from '../components/HTMLContent'
import { HoursList } from '../components/HoursList'
import { Icon, IconProps } from '../components/Icon'
import { Text } from '../components/Text'
import { DEFAULT_ONLINE_ORDERING_URL } from '../constants'

type DetailsSectionProps = {
  heading: string
  children?: React.ReactNode
}

const DetailsSection = ({ heading, children }: DetailsSectionProps) => (
  <div className="grid gap-5">
    <Text
      as="h2"
      variant="sans-18"
      className="font-bold tracking-wide uppercase text-teal-40"
    >
      {heading}
    </Text>

    <div>{children}</div>
  </div>
)

type IconTextLinkProps = {
  href: string
  iconName: IconProps['name']
  label: string
  children?: string
}

const IconTextLink = ({
  iconName,
  href,
  label,
  children,
}: IconTextLinkProps) => (
  <div>
    <dt className="sr-only">{label}</dt>
    <dd className="grid items-center justify-start grid-flow-col gap-2">
      <Icon name={iconName} className="w-5 h-5 text-red-50" />
      <Text variant="sans-18">
        <Anchor href={href} target="_blank">
          {children}
        </Anchor>
      </Text>
    </dd>
  </div>
)

type IconLinkProps = {
  href: string
  iconName: IconProps['name']
  label: string
}

const IconLink = ({ iconName, href, label }: IconLinkProps) => (
  <li className="pt-4 pl-4">
    <Anchor
      href={href}
      colorClassName="text-red-50"
      hoverColorClassName="hover:text-teal-40"
      focusColorClassName="focus:text-teal-40"
    >
      <Icon name={iconName} className="w-7 h-7" />
      <span className="sr-only">{label}</span>
    </Anchor>
  </li>
)

type FeatureProps = {
  iconName: IconProps['name']
  href?: string
  children?: React.ReactNode
}

const Feature = ({ iconName, href, children }: FeatureProps) => (
  <li className="grid items-center justify-start grid-flow-col gap-3">
    <div className="flex items-center justify-center w-8 h-8">
      <Icon name={iconName} className="w-full h-full text-red-50" />
    </div>
    <Text variant="sans-18">
      <ConditionalWrap
        condition={Boolean(href)}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        wrap={(children) => <Anchor href={href!}>{children}</Anchor>}
      >
        <>{children}</>
      </ConditionalWrap>
    </Text>
  </li>
)

export type PageBodyLocationDetailsProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyLocationDetails = ({
  title,
  type,
  specialNoteHTML,
  menuHref,
  websiteHref,
  phoneNumber,
  locationDetails,
  streetAddress,
  city,
  state,
  zipCode,
  country,
  facebookHandle,
  instagramHandle,
  twitterHandle,

  chownowHref: _chownowHref,
  ubereatsHref,
  doordashHref,
  postmatesHref,
  biteSquadHref,
  grubhubHref,
  loyaltyplantHref,

  mondayHours,
  tuesdayHours,
  wednesdayHours,
  thursdayHours,
  fridayHours,
  saturdayHours,
  sundayHours,

  acceptsGiftCards,
  providesDelivery,
  acceptsCashOnly: _acceptsCashOnly,

  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyLocationDetailsProps) => {
  const directionsHref = buildGoogleMapsDirectionsURL({
    streetAddress,
    state,
    city,
    zipCode,
    country,
  })

  return (
    <BoundedBox
      as="section"
      id={id}
      nextSharesBg={nextSharesBg}
      nextOverhangs={nextOverhangs}
      previousOverhangs={previousOverhangs}
      innerMaxWidthClassName="max-w-screen-lg"
      className="max-w-screen-xl mx-auto bg-white text-gray-10"
    >
      <div className="grid gap-8 md:gap-10 lg:gap-12">
        <div className="grid gap-4">
          {type === 'Mixplate' && (
            <Text
              variant="sans-18"
              className="font-bold tracking-wider uppercase text-teal-40"
            >
              Mixplate
            </Text>
          )}

          {title && (
            <Text
              as="h1"
              variant="sans-35-40"
              className="font-extrabold text-red-50"
            >
              {title}
            </Text>
          )}
        </div>

        <div className="grid items-baseline gap-7 md:grid-cols-3">
          <DetailsSection heading="Address">
            <div className="grid gap-5">
              {locationDetails && (
                <Text as="p" variant="sans-18">
                  {locationDetails}
                </Text>
              )}

              <Text as="p" variant="sans-18">
                <Address
                  streetAddress={streetAddress}
                  city={city}
                  state={state}
                  zipCode={zipCode}
                  country={country}
                />
              </Text>

              <dl className="grid gap-2">
                {phoneNumber && (
                  <IconTextLink
                    label="Phone Number"
                    iconName="phone"
                    href={`tel:${phoneNumber}`}
                  >
                    {phoneNumber}
                  </IconTextLink>
                )}
                {websiteHref && (
                  <IconTextLink
                    label="Website"
                    iconName="website"
                    href={websiteHref}
                  >
                    Open website
                  </IconTextLink>
                )}
                <IconTextLink
                  label="Directions"
                  iconName="directions"
                  href={directionsHref}
                >
                  Directions
                </IconTextLink>
              </dl>

              {(facebookHandle || instagramHandle || twitterHandle) && (
                <ul className="flex flex-wrap -mt-4 -ml-4">
                  {facebookHandle && (
                    <IconLink
                      href={`https://www.facebook.com/${facebookHandle}`}
                      iconName="facebookCircle"
                      label="Restaurant's Facebook page"
                    />
                  )}
                  {instagramHandle && (
                    <IconLink
                      href={`https://www.instagram.com/${instagramHandle}`}
                      iconName="instagramCircle"
                      label="Restaurant's Instagram page"
                    />
                  )}
                  {twitterHandle && (
                    <IconLink
                      href={`https://www.twitter.com/${twitterHandle}`}
                      iconName="twitterCircle"
                      label="Restaurant's Twitter page"
                    />
                  )}
                </ul>
              )}

              <ul className="flex flex-wrap -mt-4 -ml-4">
                <li className="pt-4 pl-4">
                  <ButtonLink
                    variant="redSmall"
                    href={loyaltyplantHref ?? DEFAULT_ONLINE_ORDERING_URL}
                    target="_blank"
                  >
                    Order Now
                  </ButtonLink>
                </li>

                {menuHref && (
                  <li className="pt-4 pl-4">
                    <ButtonLink
                      variant="redSmall"
                      href={menuHref}
                      target="_blank"
                    >
                      Menu
                    </ButtonLink>
                  </li>
                )}
              </ul>
            </div>
          </DetailsSection>

          <DetailsSection heading="Hours">
            <div className="grid gap-5">
              {specialNoteHTML && <HTMLContent html={specialNoteHTML} />}

              <HoursList
                monday={mondayHours}
                tuesday={tuesdayHours}
                wednesday={wednesdayHours}
                thursday={thursdayHours}
                friday={fridayHours}
                saturday={saturdayHours}
                sunday={sundayHours}
              />
            </div>
          </DetailsSection>

          {(providesDelivery ||
            ubereatsHref ||
            doordashHref ||
            postmatesHref ||
            biteSquadHref ||
            grubhubHref ||
            loyaltyplantHref) && (
            <DetailsSection heading="Services">
              <ul className="grid justify-start gap-2">
                {ubereatsHref && (
                  <Feature iconName="ubereats" href={ubereatsHref}>
                    UberEats
                  </Feature>
                )}
                {doordashHref && (
                  <Feature iconName="doordash" href={doordashHref}>
                    DoorDash
                  </Feature>
                )}
                {postmatesHref && (
                  <Feature iconName="postmates" href={postmatesHref}>
                    Postmates
                  </Feature>
                )}
                {biteSquadHref && (
                  <Feature iconName="biteSquad" href={biteSquadHref}>
                    Bite Squad
                  </Feature>
                )}
                {grubhubHref && (
                  <Feature iconName="grubhub" href={grubhubHref}>
                    Grubhub
                  </Feature>
                )}
                {loyaltyplantHref && (
                  <Feature iconName="onlineOrdering" href={loyaltyplantHref}>
                    Online Ordering
                  </Feature>
                )}
                {providesDelivery && (
                  <Feature iconName="delivery">Delivery</Feature>
                )}
                {acceptsGiftCards && (
                  <Feature iconName="giftCard">Accepts Gift Cards</Feature>
                )}
              </ul>
            </DetailsSection>
          )}
        </div>
      </div>
    </BoundedBox>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyLocationDetailsFragment,
  typeof mapDataToContext
>) => ({
  title: data.data?.title?.text,
  type: data.data?.type,
  specialNoteHTML: getRichText(data.data?.special_note),
  menuHref: data.data?.menu?.url,
  websiteHref: data.data?.website_url?.url,
  phoneNumber: data.data?.phone_number?.text,
  locationDetails: data.data?.location_details?.text,
  streetAddress: data.data?.street_address?.text,
  city: data.data?.city?.text,
  state: data.data?.state?.text,
  zipCode: data.data?.zip_code?.text,
  country: data.data?.country?.text,
  facebookHandle: data.data?.facebook_handle?.text,
  instagramHandle: data.data?.instagram_handle?.text,
  twitterHandle: data.data?.twitter_handle?.text,

  chownowHref: data.data?.chownow_url?.url,
  ubereatsHref: data.data?.ubereats_url?.url,
  doordashHref: data.data?.doordash_url?.url,
  postmatesHref: data.data?.postmates_url?.url,
  biteSquadHref: data.data?.bite_squad_url?.url,
  grubhubHref: data.data?.grubhub_url?.url,
  loyaltyplantHref: undefIfEmpty(data.data?.loyaltyplant_url?.url),

  mondayHours:
    data.data?.monday_hours?.text ||
    toTimeRange(data.data?.monday_opening_time, data.data?.monday_closing_time),
  tuesdayHours:
    data.data?.tuesday_hours?.text ||
    toTimeRange(
      data.data?.tuesday_opening_time,
      data.data?.tuesday_closing_time,
    ),
  wednesdayHours:
    data.data?.wednesday_hours?.text ||
    toTimeRange(
      data.data?.wednesday_opening_time,
      data.data?.wednesday_closing_time,
    ),
  thursdayHours:
    data.data?.thursday_hours?.text ||
    toTimeRange(
      data.data?.thursday_opening_time,
      data.data?.thursday_closing_time,
    ),
  fridayHours:
    data.data?.friday_hours?.text ||
    toTimeRange(data.data?.friday_opening_time, data.data?.friday_closing_time),
  saturdayHours:
    data.data?.saturday_hours?.text ||
    toTimeRange(
      data.data?.saturday_opening_time,
      data.data?.saturday_closing_time,
    ),
  sundayHours:
    data.data?.sunday_hours?.text ||
    toTimeRange(data.data?.sunday_opening_time, data.data?.sunday_closing_time),

  acceptsGiftCards: data.data?.accepts_gift_cards,
  providesDelivery: data.data?.provides_delivery,
  acceptsCashOnly: data.data?.accepts_cash_only,
})

export const mapDataToContext = () => ({
  bg: 'bg-white',
})

export const fragment = graphql`
  # Note: This isn't a slice fragment since this slice does not exist in Prismic.
  fragment PageBodyLocationDetails on PrismicLocation {
    data {
      title {
        text
      }
      type
      special_note {
        text
        html
      }
      menu {
        url
      }
      website_url {
        url
      }
      phone_number {
        text
      }
      location_details {
        text
      }
      street_address {
        text
      }
      city {
        text
      }
      state {
        text
      }
      zip_code {
        text
      }
      country {
        text
      }
      coordinates {
        latitude
        longitude
      }
      facebook_handle {
        text
      }
      instagram_handle {
        text
      }
      twitter_handle {
        text
      }

      ###
      # Ordering
      ###
      chownow_url {
        url
      }
      ubereats_url {
        url
      }
      doordash_url {
        url
      }
      postmates_url {
        url
      }
      bite_squad_url {
        url
      }
      grubhub_url {
        url
      }
      loyaltyplant_url {
        url
      }

      ###
      # Hours
      ###
      monday_hours {
        text
      }
      monday_opening_time
      monday_closing_time
      tuesday_hours {
        text
      }
      tuesday_opening_time
      tuesday_closing_time
      wednesday_hours {
        text
      }
      wednesday_opening_time
      wednesday_closing_time
      thursday_hours {
        text
      }
      thursday_opening_time
      thursday_closing_time
      friday_hours {
        text
      }
      friday_opening_time
      friday_closing_time
      saturday_hours {
        text
      }
      saturday_opening_time
      saturday_closing_time
      sunday_hours {
        text
      }
      sunday_opening_time
      sunday_closing_time

      ###
      # Features
      ###
      accepts_gift_cards
      provides_delivery
      accepts_cash_only
    }
  }
`

export default PageBodyLocationDetails
