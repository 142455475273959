import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'
import clsx from 'clsx'

import { PageBodyCalloutBoxFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StandardGrid } from '../components/StandardGrid'
import { StyledHTMLContent } from '../components/StyledHTMLContent'

export type PageBodyCalloutBoxProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyCalloutBox = ({
  textHTML,
  imageFluid,
  imageAlt,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
  nextIsFooter,
}: PageBodyCalloutBoxProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-md"
    ptClassName={clsx('pb-0', nextIsFooter && 'md:pb-13 lg:pb-16')}
    pbClassName="pb-0"
    className="mx-auto bg-white text-gray-10 max-w-screen-xl"
  >
    <div className="flex flex-col">
      <BoundedBox
        ptClassName="pt-8 md:pt-10 lg:pt-12"
        pbClassName="pb-8 md:pb-10 lg:pb-12"
        className={clsx(
          '-mx-6 bg-yellow-50 md:-mx-0 md:shadow-xl lg:shadow-2xl md:relative md:z-10 md:-mt-9 lg:-mt-12',
          !nextIsFooter && 'md:-mb-9 lg:-mb-12',
        )}
      >
        <StandardGrid>
          {imageFluid && (
            <div className="self-start border-white shadow border-6 md:border-8 col-span-5 md:col-span-4 -mt-13 md:-my-15 lg:-my-17">
              <GatsbyImage fluid={imageFluid} alt={imageAlt} />
            </div>
          )}

          <div
            className={clsx(
              'self-center',
              imageFluid ? 'col-span-7 md:col-span-8' : 'col-span-full',
            )}
          >
            {textHTML && (
              <StyledHTMLContent
                html={textHTML}
                className={clsx(!imageFluid && 'text-center')}
              />
            )}
          </div>
        </StandardGrid>
      </BoundedBox>
    </div>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyCalloutBoxFragment,
  typeof mapDataToContext
>) => ({
  textHTML: getRichText(data?.primary?.text),
  imageFluid: data?.primary?.image?.fluid,
  imageAlt: data?.primary?.image?.alt,
})

export const mapDataToContext = () => ({
  bg: ['bg-yellow-50', 'bg-white'],
  overhangsBottom: [false, true],
  overhangsTop: [false, true],
})

export const fragment = graphql`
  fragment PageBodyCalloutBox on PrismicPageBodyCalloutBox {
    primary {
      text {
        text
        html
      }
      image {
        alt
        fluid(maxWidth: 400) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyCalloutBox
