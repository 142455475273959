type BuildGoogleMapsDirectionsURLArgs = {
  streetAddress?: string
  state?: string
  city?: string
  zipCode?: string
  country?: string
}

export const buildGoogleMapsDirectionsURL = ({
  streetAddress,
  state,
  city,
  zipCode,
  country,
}: BuildGoogleMapsDirectionsURLArgs) =>
  `http://www.google.com/maps/dir/${encodeURIComponent(
    [streetAddress, state, city, zipCode, country].filter(Boolean).join(' '),
  )}`
