import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import VisuallyHidden from '@reach/visually-hidden'
import DatePicker from 'react-date-picker'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import clsx from 'clsx'

import { useSiteSettings } from '../hooks/useSiteSettings'

import { BoundedBox } from './BoundedBox'
import { Button } from './Button'
import { FancyHeading } from './FancyHeading'
import { HTMLContent } from './HTMLContent'
import { Text } from './Text'
import { Icon } from './Icon'

type RadioFieldOptionProps = {
  name: string
  value?: string
  label: string
  checkedValue: string
  setState: (value: string) => void
}

const RadioFieldOption = ({
  name,
  value,
  label,
  checkedValue,
  setState,
}: RadioFieldOptionProps) => (
  <label className="items-center grid gap-2 grid-flow-col">
    <input
      type="radio"
      name={name}
      value={value}
      checked={value === checkedValue}
      onChange={(event) => setState(event.currentTarget.value)}
    />
    <Text variant="sans-13">{label}</Text>
  </label>
)

type FieldShellOwnProps = {
  label: string
  children?: React.ReactNode
}

export type FieldShellProps<
  T extends React.ElementType = 'label'
> = PolymorphicPropsWithoutRef<FieldShellOwnProps, T>

const FieldShell = <T extends React.ElementType = 'label'>({
  as,
  label,
  children,
  className,
  ...restProps
}: FieldShellProps<T>) => {
  const Element: React.ElementType = as ?? 'label'

  return (
    <Element className={clsx('grid gap-3', className)} {...restProps}>
      <Text
        variant="sans-13"
        className="font-bold tracking-widest uppercase text-red-50"
      >
        {label}
      </Text>
      <div>{children}</div>
    </Element>
  )
}

type FieldProps = {
  label: string
  name: string
  type?: string
  required?: boolean
  value: string
  setState?: (value: string) => void
}

const Field = React.forwardRef<HTMLInputElement, FieldProps>(
  ({ label, name, type = 'text', required = false, value, setState }, ref) => (
    <FieldShell label={label}>
      <input
        ref={ref}
        type={type}
        name={name}
        required={required}
        onChange={(event) => setState?.(event.currentTarget.value)}
        value={value}
        className="w-full px-2 py-1 font-sans border-gray-60 text-16"
      />
    </FieldShell>
  ),
)

/**
 * Simple date formatter in MM/DD/YYYY format.
 */
const formatDate = (date: Date): string => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()

  return `${month}/${day}/${year}`
}

type NewsletterDialogProps = {
  closeDialog: () => void
}

export const NewsletterDialog = ({ closeDialog }: NewsletterDialogProps) => {
  const siteSettings = useSiteSettings()

  // const [LazyPikaday, setPikaday] = React.useState<unknown>()
  const [fname, setFname] = React.useState('')
  const [lname, setLname] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [zipCode, setZipCode] = React.useState('')
  const [birthday, setBirthday] = React.useState<Date>()
  const [gender, setGender] = React.useState('')

  // const birthdayFieldRef = React.useRef<HTMLInputElement>(null)
  // const birthdayPikadayRef = React.useRef<Pikaday>()

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [successMsg, setSuccessMsg] = React.useState<string>()
  const [errorMsg, setErrorMsg] = React.useState<string>()

  // const loadPikadayModule = async () => {
  //   const pikadayModule = await import('pikaday')

  //   setPikaday(pikadayModule.default)
  // }

  // React.useEffect(() => {
  //   loadPikadayModule()
  // }, [])

  // React.useEffect(() => {
  //   if (LazyPikaday && birthdayFieldRef.current) {
  //     // @ts-expect-error - Sorry, don't have time to do TypeScript gymnastics
  //     birthdayPikadayRef.current = new LazyPikaday({
  //       field: birthdayFieldRef.current,
  //       // @ts-expect-error - Sorry, don't have time to do TypeScript gymnastics
  //       onSelect: (date) => setBirthday(formatDate(date)),
  //     })
  //   }
  // }, [LazyPikaday])

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsSubmitting(true)
    setSuccessMsg(undefined)
    setErrorMsg(undefined)

    const res = await addToMailchimp(email, {
      FNAME: fname,
      LNAME: lname,
      ZIPCODE: zipCode,
      BIRTHDAY: birthday ? formatDate(birthday) : '',
      GENDER: gender,
    })

    if (res.result === 'error') {
      setErrorMsg(res.msg)
    } else if (res.result === 'success') {
      setSuccessMsg(res.msg)
    }

    setIsSubmitting(false)
  }

  return (
    <div className="relative bg-white shadow-lg md:shadow-xl lg:shadow-2xl">
      <BoundedBox className="text-white bg-center bg-teal-40 bg-pattern-red bg-w-80rem">
        <div className="flex items-center h-full">
          {siteSettings.newsletterPopupTextHTML && (
            <HTMLContent
              html={siteSettings.newsletterPopupTextHTML}
              componentOverrides={{
                h1: () => (props) => (
                  <FancyHeading
                    as="h1"
                    variant="yellow"
                    textVariant="sans-48-64"
                    {...props}
                    className="mb-7 md:mb-8 last:mb-0"
                  />
                ),
                p: (Comp) => (props) => <Comp className="w-10/12" {...props} />,
              }}
            />
          )}
        </div>
      </BoundedBox>
      <BoundedBox
        ptClassName="pt-10"
        pbClassName="pb-10"
        className="bg-white text-gray-10"
      >
        <form action="" method="get" onSubmit={onSubmit}>
          <div className="grid gap-7">
            <div className="grid grid-cols-2 gap-5">
              <Field
                label="First Name"
                name="FNAME"
                required={true}
                value={fname}
                setState={setFname}
              />
              <Field
                label="Last Name"
                name="LNAME"
                required={true}
                value={lname}
                setState={setLname}
              />
              <div className="col-span-full">
                <Field
                  label="Email Address"
                  name="EMAIL"
                  type="email"
                  required={true}
                  value={email}
                  setState={setEmail}
                />
              </div>
              <Field
                label="Zip Code"
                name="ZIPCODE"
                required={true}
                value={zipCode}
                setState={setZipCode}
              />
              <FieldShell label="Birthday">
                <DatePicker
                  onChange={(date) =>
                    setBirthday(Array.isArray(date) ? date[0] : date)
                  }
                  value={birthday}
                />
              </FieldShell>
              <div className="col-span-full">
                <FieldShell as="div" label="Gender">
                  <div className="justify-start grid gap-4 grid-flow-col">
                    <RadioFieldOption
                      name="GENDER"
                      value="Male"
                      label="Male"
                      checkedValue={gender}
                      setState={setGender}
                    />
                    <RadioFieldOption
                      name="GENDER"
                      value="Female"
                      label="Female"
                      checkedValue={gender}
                      setState={setGender}
                    />
                    <RadioFieldOption
                      name="GENDER"
                      value=""
                      label="Prefer not to specify"
                      checkedValue={gender}
                      setState={setGender}
                    />
                  </div>
                </FieldShell>
              </div>
            </div>
            {errorMsg && (
              <Text as="p" variant="sans-15" className="text-red-50">
                Oops! Something went wrong.
                <br />
                {errorMsg}
              </Text>
            )}
            {successMsg ? (
              <div className="grid gap-4 justify-items-start">
                <Text
                  as="p"
                  variant="sans-18"
                  className="font-bold text-teal-40"
                >
                  Success!
                </Text>
                <Text as="p" variant="sans-16">
                  {successMsg}
                </Text>
                <Button variant="tealSmall" onClick={closeDialog}>
                  Go back to the site
                </Button>
              </div>
            ) : (
              <Button
                variant="tealLarge"
                type="submit"
                disabled={isSubmitting || Boolean(successMsg)}
                className="justify-self-start"
              >
                Sign me up!
              </Button>
            )}
          </div>
        </form>
      </BoundedBox>

      <button
        onClick={closeDialog}
        className="absolute top-0 right-0 p-2 text-white bg-teal-40 hover:bg-teal-20 focus:bg-teal-20 transition"
      >
        <VisuallyHidden>Close popup</VisuallyHidden>
        <Icon name="close" className="w-7" />
      </button>
    </div>
  )
}
