import * as React from 'react'
import { graphql } from 'gatsby'
import { buildImgixFluid } from 'gatsby-plugin-imgix'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'
import clsx from 'clsx'

import { PageBodyCalloutFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StandardGrid } from '../components/StandardGrid'
import { HTMLContent } from '../components/HTMLContent'

const backgroundTextureImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/d69a6084-6c6a-4dc5-a39f-6e5d97d5cbac_texture-dots-surfboards.png',
  sourceWidth: 1440,
  sourceHeight: 600,
  args: {
    imgixParams: {
      auto: 'compress,format',
      q: 50,
      w: 1200,
      fit: 'max',
    },
  },
})

export type PageBodyCalloutProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyCallout = ({
  textHTML,
  imageFluid,
  imageAlt,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyCalloutProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-md"
    ptClassName="pt-9 md:pt-10 lg:pt-11"
    pbClassName="pb-9 md:pb-10 lg:pb-11"
    className="relative mx-auto bg-yellow-50 text-gray-10 max-w-screen-xl"
  >
    <div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
      <GatsbyImage
        fluid={backgroundTextureImageFluid}
        alt=""
        className="w-full h-full"
      />
    </div>

    <StandardGrid className="relative">
      {imageFluid && (
        <div className="-my-9 col-span-4 md:col-span-3 md:-my-10 lg:-my-11">
          <GatsbyImage
            fluid={imageFluid}
            alt={imageAlt}
            className="block -mb-6"
          />
        </div>
      )}

      <div
        className={clsx(
          'self-center',
          imageFluid ? 'col-span-8 md:col-span-9' : 'col-span-full',
        )}
      >
        {textHTML && (
          <HTMLContent
            html={textHTML}
            componentOverrides={{
              h1: (Comp) => (props) => <Comp variant="sans-24-30" {...props} />,
            }}
            className={clsx(!imageFluid && 'text-center')}
          />
        )}
      </div>
    </StandardGrid>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyCalloutFragment, typeof mapDataToContext>) => ({
  textHTML: getRichText(data?.primary?.text),
  imageFluid: data?.primary?.image?.fluid,
  imageAlt: data?.primary?.image?.alt,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const fragment = graphql`
  fragment PageBodyCallout on PrismicPageBodyCallout {
    primary {
      text {
        text
        html
      }
      image {
        alt
        fluid(maxWidth: 400) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyCallout
