import * as React from 'react'
import { graphql } from 'gatsby'
import VisuallyHidden from '@reach/visually-hidden'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import clsx from 'clsx'

import { PageBodyQuoteFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { StandardGrid } from '../components/StandardGrid'
import { Text } from '../components/Text'

export type PageBodyQuoteProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyQuote = ({
  imageFluid,
  imageAlt,
  quote,
  credit,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
  nextIsFooter,
}: PageBodyQuoteProps): JSX.Element => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-70rem"
    ptClassName="pt-0"
    pbClassName={nextIsFooter ? 'pb-0 md:pb-13 lg:pb-16' : 'pb-0'}
    className="max-w-screen-xl mx-auto bg-white text-gray-10"
  >
    <div className="flex flex-col">
      <div
        className={clsx(
          '-mx-6 bg-white md:-mx-0 md:shadow-xl lg:shadow-2xl md:relative md:z-10 md:-mt-9 lg:-mt-12',
          !nextIsFooter && 'md:-mb-9 lg:-mb-12',
        )}
      >
        <StandardGrid gapXClassName="gap-0" className="grid-flow-row-dense">
          {imageFluid && (
            <div className="col-span-full md:col-span-4">
              <div className="h-full aspect-w-3 aspect-h-2 bg-gray-60 md:aspect-w-1 md:aspect-h-1">
                <div>
                  <GatsbyImage
                    fluid={imageFluid}
                    alt={imageAlt}
                    className="h-full"
                  />
                </div>
              </div>
            </div>
          )}

          <BoundedBox
            plClassName="pl-10 md:pl-13 lg:pl-16"
            prClassName="pr-10 md:pr-13 lg:pr-16"
            className={clsx(
              'bg-white text-gray-10 col-span-full',
              imageFluid && 'md:col-span-8',
            )}
          >
            {quote && (
              <figure className="grid content-center h-full gap-8 md:gap-9">
                <blockquote>
                  <Text
                    as="p"
                    variant="sans-35-40"
                    className="relative font-extrabold text-teal-40"
                  >
                    <div className="absolute top-0 left-0 block transform -translate-x-full">
                      <Text variant="sans-35-40" as="span">
                        &ldquo;
                      </Text>
                    </div>
                    {quote}&rdquo;
                  </Text>
                </blockquote>
                {credit && (
                  <figcaption>
                    <Text variant="sans-18" as="span">
                      &mdash; {credit}
                    </Text>
                  </figcaption>
                )}
              </figure>
            )}
          </BoundedBox>
        </StandardGrid>
      </div>
    </div>
  </BoundedBox>
)

type PromoBoxProps = {
  index: number
  textHTML?: string
  imageFluid?: FluidObject
  imageAlt?: string
  buttonText?: string
  buttonHref?: string
  buttonTarget?: string
}

const PromoBox = ({
  index,
  textHTML,
  imageFluid,
  imageAlt,
  buttonHref,
  buttonText = 'Learn more',
  buttonTarget,
}: PromoBoxProps) => {
  const imageIsOnRight = index % 2 === 1

  return (
    <li className="border-b-2 border-gray-80 last:border-b-0">
      <StandardGrid gapXClassName="gap-0" className="grid-flow-row-dense">
        <div
          className={clsx(
            'col-span-full md:col-span-4',
            imageIsOnRight && 'md:col-start-9',
          )}
        >
          <div className="h-full aspect-w-3 aspect-h-2 bg-gray-60 md:aspect-w-1 md:aspect-h-1">
            <div>
              {imageFluid && (
                <GatsbyImage
                  fluid={imageFluid}
                  alt={imageAlt}
                  className="h-full"
                />
              )}
            </div>
          </div>
        </div>

        <BoundedBox
          className={clsx(
            'bg-white text-gray-10 col-span-full md:col-span-8',
            imageIsOnRight && 'md:col-start-1',
          )}
        >
          <div className="grid content-center h-full gap-7 md:gap-8 justify-items-center md:justify-items-start md:max-w-40rem md:ml-auto">
            {textHTML && (
              <HTMLContent
                html={textHTML}
                componentOverrides={{
                  h1: (Comp) => (props) => (
                    <Comp className="text-red-50" {...props} />
                  ),
                }}
                className="text-center md:text-left"
              />
            )}
            {buttonHref && (
              <ButtonLink
                variant="tealLarge"
                href={buttonHref}
                target={buttonTarget}
              >
                <VisuallyHidden>
                  Learn more by visiting {buttonHref}
                </VisuallyHidden>
                {buttonText}
              </ButtonLink>
            )}
          </div>
        </BoundedBox>
      </StandardGrid>
    </li>
  )
}

PageBodyQuote.PromoBox = PromoBox

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyQuoteFragment, typeof mapDataToContext>) => ({
  quote: data.primary?.quote?.text,
  credit: data.primary?.credit?.text,
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
})

export const mapDataToContext = () => ({
  bgTop: [Symbol(), 'bg-white'],
  bgBottom: 'bg-white',
  overhangsBottom: [false, true],
  overhangsTop: [false, true],
})

export const fragment = graphql`
  fragment PageBodyQuote on PrismicPageBodyQuote {
    primary {
      quote {
        text
      }
      credit {
        text
      }
      image {
        alt
        fluid(maxWidth: 500) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyQuote
