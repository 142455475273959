import * as React from 'react'
import { graphql } from 'gatsby'
import * as ReachTabs from '@reach/tabs'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import VisuallyHidden from '@reach/visually-hidden'
import ReactPlayer from 'react-player'
import clsx from 'clsx'

import { PageBodyVideosFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Icon } from '../components/Icon'

type VideoPanelProps = {
  index: number
  videoUrl?: string
  posterImageFluid?: FluidObject
  posterImageAlt?: string
}

const VideoTabPanel = ({
  index,
  videoUrl,
  posterImageFluid,
  posterImageAlt,
}: VideoPanelProps) => {
  const { selectedIndex } = ReachTabs.useTabsContext()
  const isSelected = index === selectedIndex

  const [isPlaying, setPlaying] = React.useState(false)
  const play = () => setPlaying(true)
  const stop = () => setPlaying(false)
  const pause = stop

  const [hasStarted, setHasStarted] = React.useState(false)

  const onStart = () => setHasStarted(true)
  const onPlay = play
  const onPause = pause
  const onEnded = () => {
    setHasStarted(false)
    stop()
  }

  React.useEffect(() => {
    if (!isSelected) {
      pause()
    }
  }, [isSelected, pause])

  return (
    <div className="aspect-w-16 aspect-h-9">
      <div>
        <button
          onClick={play}
          className={clsx(
            'block absolute inset-0 transition w-full bg-gray-10 group',
            hasStarted
              ? 'opacity-0 pointer-events-none'
              : 'opacity-100 pointer-events-auto',
          )}
        >
          <VisuallyHidden>Play video</VisuallyHidden>
          {posterImageFluid && (
            <GatsbyImage
              fluid={posterImageFluid}
              alt={posterImageAlt}
              className={clsx('h-full transition', isPlaying && 'opacity-0')}
            />
          )}
          <div
            className={clsx(
              'py-4 bg-teal-40 md:py-6 px-7 md:px-14 bg-opacity-75 group-hover:bg-opacity-100 group-focus:bg-opacity-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition',
              isPlaying && 'opacity-0',
            )}
          >
            <Icon name="play" className="w-6 text-white md:w-8" />
          </div>
        </button>
        {videoUrl && (
          <ReactPlayer
            url={videoUrl}
            playing={isPlaying}
            controls={true}
            onStart={onStart}
            onPlay={onPlay}
            onPause={onPause}
            onEnded={onEnded}
            width="100%"
            height="100%"
            config={{
              youtube: {
                embedOptions: {
                  modestbranding: 1,
                },
              },
            }}
          />
        )}
      </div>
    </div>
  )
}

type VideoTabProps = {
  index: number
  posterImageFluid?: FluidObject
  posterImageAlt?: string
}

const VideoTab = ({
  index,
  posterImageFluid,
  posterImageAlt,
}: VideoTabProps) => {
  const { selectedIndex } = ReachTabs.useTabsContext()
  const isSelected = index === selectedIndex

  return (
    <div className="relative">
      <div
        className={clsx(
          'aspect-w-16 aspect-h-9 border-2 md:border-4 transition',
          isSelected ? 'border-red-50' : 'border-transparent',
        )}
      >
        <div>
          {posterImageFluid && (
            <GatsbyImage
              fluid={posterImageFluid}
              alt={posterImageAlt}
              className="h-full"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export type PageBodyVideosProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyVideos = ({
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
  nextIsFooter,
}: PageBodyVideosProps) => {
  const hasMultipleVideos = React.Children.count(children) > 1

  return (
    <BoundedBox
      as="section"
      id={id}
      nextSharesBg={nextSharesBg}
      nextOverhangs={nextOverhangs}
      previousOverhangs={previousOverhangs}
      innerMaxWidthClassName="max-w-70rem"
      ptClassName="pt-0"
      pbClassName={nextIsFooter ? undefined : 'pb-0'}
      className="mx-auto bg-white max-w-screen-xl"
    >
      <div className="flex flex-col">
        <div
          className={clsx(
            '-mx-6 bg-white md:-mx-0 md:shadow-xl lg:shadow-2xl md:relative md:z-10 md:-mt-9 lg:-mt-12',
            !nextIsFooter && 'md:-mb-9 lg:-mb-12',
          )}
        >
          <ReachTabs.Tabs>
            <ReachTabs.TabPanels>
              {React.Children.map(children, (child, index) => {
                if (!React.isValidElement(child)) {
                  return null
                }

                const props = child.props as PageBodyVideosVideoProps

                return (
                  <ReachTabs.TabPanel>
                    <VideoTabPanel
                      key={props.name}
                      index={index}
                      videoUrl={props.videoUrl}
                      posterImageFluid={props.posterImageFluid}
                      posterImageAlt={props.posterImageAlt}
                    />
                  </ReachTabs.TabPanel>
                )
              })}
            </ReachTabs.TabPanels>
            {hasMultipleVideos && (
              <BoundedBox
                ptClassName="pt-6 md:pt-8"
                pbClassName="pb-6 md:pb-8"
                plClassName="pl-6 md:pl-8"
                prClassName="pr-6 md:pr-8"
                className="bg-gray-10"
              >
                <ReachTabs.TabList className="flex flex-wrap justify-center -mt-4 -ml-4 md:-mt-6 md:-ml-6">
                  {React.Children.map(children, (child, index) => {
                    if (!React.isValidElement(child)) {
                      return null
                    }

                    const props = child.props as PageBodyVideosVideoProps

                    return (
                      <div className="w-1/3 pt-4 pl-4 md:w-1/4 md:pt-6 md:pl-6">
                        <ReachTabs.Tab className="block w-full">
                          <VideoTab
                            key={props.name}
                            index={index}
                            posterImageFluid={props.posterImageFluid}
                            posterImageAlt={props.posterImageAlt}
                          />
                        </ReachTabs.Tab>
                      </div>
                    )
                  })}
                </ReachTabs.TabList>
              </BoundedBox>
            )}
          </ReachTabs.Tabs>
        </div>
      </div>
    </BoundedBox>
  )
}

type PageBodyVideosVideoProps = {
  name?: string
  videoUrl?: string
  posterImageFluid?: FluidObject
  posterImageAlt?: string
}

const PageBodyVideosVideo = (_props: PageBodyVideosVideoProps) => null
PageBodyVideos.Video = PageBodyVideosVideo

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyVideosFragment, typeof mapDataToContext>) => ({
  children: data.items?.map?.((item) => (
    <PageBodyVideos.Video
      key={item?.video_url?.embed_url}
      videoUrl={item?.video_url?.embed_url}
      posterImageFluid={item?.poster_image?.fluid}
      posterImageAlt={item?.poster_image?.alt}
    />
  )),
})

export const mapDataToContext = () => ({
  bg: 'bg-white',
  overhangsBottom: [false, true],
  overhangsTop: [false, true],
})

export const fragment = graphql`
  fragment PageBodyVideos on PrismicPageBodyVideos {
    items {
      video_url {
        embed_url
      }
      poster_image {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyVideos
