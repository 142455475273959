import * as React from 'react'
import { graphql } from 'gatsby'

import { PageBodyCognitoFormFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'
import { useExternalScript } from '../hooks/useExternalScript'

import { BoundedBox } from '../components/BoundedBox'

declare global {
  interface Window {
    Cognito?: {
      load: (contentType: string, config: Record<string, unknown>) => void
    }
  }
}

export type PageBodyCognitoFormProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyCognitoForm = ({
  formId,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyCognitoFormProps) => {
  const scriptSrc = formId
    ? `https://www.cognitoforms.com/s/${process.env.GATSBY_COGNITO_FORMS_ID}`
    : undefined

  const cognitoApiIsLoaded = useExternalScript(scriptSrc)

  React.useEffect(() => {
    if (cognitoApiIsLoaded && window.Cognito) {
      window.Cognito.load('forms', { id: formId })
    }
  }, [cognitoApiIsLoaded])

  return (
    <BoundedBox
      as="section"
      id={id}
      nextSharesBg={nextSharesBg}
      nextOverhangs={nextOverhangs}
      previousOverhangs={previousOverhangs}
      innerMaxWidthClassName="max-w-screen-md"
      className="max-w-screen-xl mx-auto bg-white text-gray-10"
    >
      {formId && <div className="cognito" />}
    </BoundedBox>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyCognitoFormFragment,
  typeof mapDataToContext
>) => ({
  formId: data.primary?.form_id,
})

export const mapDataToContext = () => ({
  bg: 'bg-white',
})

export const fragment = graphql`
  fragment PageBodyCognitoForm on PrismicPageBodyCognitoForm {
    primary {
      form_id
    }
  }
`

export default PageBodyCognitoForm
