import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { buildImgixFluid } from 'gatsby-plugin-imgix'
import { getRichText } from '@walltowall/helpers'
import ConditionalWrap from 'conditional-wrap'

import { PageBodyLlPartnersFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'

import AssetTextureRoughPaper from '../assets/texture-rough-paper.jpg'

const hulaGirl1ImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/e81e1252-3b3d-4f0c-9aa9-88a7adefbbf0_luau-and-learn-hula-girl-1.png',
  sourceWidth: 885,
  sourceHeight: 1388,
  args: {
    imgixParams: {
      auto: 'compress,format',
      q: 50,
      w: 885,
      fit: 'max',
    },
  },
})

const hulaGirl2ImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/da9cc75c-8dfc-4e7f-9a60-eed8b4ee88ac_luau-and-learn-hula-girl-2.png',
  sourceWidth: 539,
  sourceHeight: 945,
  args: {
    imgixParams: {
      auto: 'compress,format',
      q: 50,
      w: 539,
      fit: 'max',
    },
  },
})

export type PageBodyLLPartnersProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyLLPartners = ({
  textHTML,
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyLLPartnersProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-lg"
    className="relative mx-auto overflow-hidden bg-repeat-y bg-w-full white text-gray-10 max-w-screen-xl"
    style={{ backgroundImage: `url('${AssetTextureRoughPaper}')` }}
  >
    <div className="relative">
      <div className="absolute bottom-0 left-0 hidden md:block transform translate-y-1/2 lg:-translate-x-2/3">
        <GatsbyImage
          fluid={hulaGirl1ImageFluid}
          alt="Hula girl dancing"
          className="w-23 md:w-12rem lg:w-15rem"
        />
      </div>

      <div className="absolute bottom-0 right-0 hidden md:block transform translate-y-1/2 lg:translate-x-1/2">
        <GatsbyImage
          fluid={hulaGirl2ImageFluid}
          alt="Hula girl playing an ukulele"
          className="w-20 md:w-10rem lg:w-12rem"
        />
      </div>

      <div className="grid gap-8 lg:gap-10">
        {textHTML && (
          <HTMLContent
            html={textHTML}
            componentOverrides={{
              h1: (Comp) => (props) => (
                <Comp className="text-green-50" {...props} />
              ),
              p: (Comp) => (props) => <Comp variant="sans-16" {...props} />,
            }}
            className="w-full mx-auto text-center max-w-screen-md"
          />
        )}

        <ul className="flex flex-col justify-center -mt-6 -ml-10 md:flex-wrap items-center lg:flex-row">
          {children}
        </ul>
      </div>
    </div>
  </BoundedBox>
)

type PartnerProps = {
  logoImageFluid?: FluidObject
  logoImageAlt?: string
  href?: string
  target?: string
}

const Partner = ({
  logoImageFluid,
  logoImageAlt,
  href,
  target,
}: PartnerProps) =>
  logoImageFluid ? (
    <li className="pt-6 pl-10">
      <ConditionalWrap
        condition={Boolean(href)}
        wrap={(children) => (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <Link href={href!} target={target}>
            {children}
          </Link>
        )}
      >
        <GatsbyImage
          fluid={logoImageFluid}
          alt={logoImageAlt}
          className="w-12rem"
        />
      </ConditionalWrap>
    </li>
  ) : null

PageBodyLLPartners.Partner = Partner

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyLlPartnersFragment,
  typeof mapDataToContext
>) => ({
  textHTML: getRichText(data.primary?.text),
  children: data.items?.map((item) => (
    <PageBodyLLPartners.Partner
      key={item?.partner_link?.url}
      logoImageFluid={item?.partner_logo?.fluid}
      logoImageAlt={item?.partner_logo?.alt}
      href={item?.partner_link?.url}
      target={item?.partner_link?.target}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const fragment = graphql`
  fragment PageBodyLLPartners on PrismicPageBodyLLPartners {
    primary {
      text {
        text
        html
      }
    }
    items {
      partner_logo {
        alt
        fluid(maxWidth: 200) {
          ...GatsbyPrismicImageFluid
        }
      }
      partner_link {
        url
        target
      }
    }
  }
`

export default PageBodyLLPartners
