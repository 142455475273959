import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { buildImgixFluid } from 'gatsby-plugin-imgix'
import VisuallyHidden from '@reach/visually-hidden'
import { getRichText } from '@walltowall/helpers'

import { PageBodyLlContentFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { Link } from '../components/Link'

const spotifyButtonImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/3c8103ee-abaf-4a83-b971-4c7062afc2b2_spotify-button.png',
  sourceWidth: 300,
  sourceHeight: 110,
  args: {
    maxWidth: 300,
    imgixParams: {
      auto: 'compress,format',
      q: 100,
      fit: 'max',
    },
  },
})

export type PageBodyLLContentProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyLLContent = ({
  introTextHTML,
  disclaimerTextHTML,
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyLLContentProps) => {
  const hasChildren = React.Children.count(children) > 0

  return (
    <BoundedBox
      as="section"
      id={id}
      nextSharesBg={nextSharesBg}
      nextOverhangs={nextOverhangs}
      previousOverhangs={previousOverhangs}
      innerMaxWidthClassName="max-w-70rem"
      className="max-w-screen-xl mx-auto text-white bg-orange-60"
    >
      <div className="grid gap-10 md:gap-12">
        {introTextHTML && (
          <HTMLContent
            html={introTextHTML}
            componentOverrides={{
              h1: (Comp) => (props) => (
                <Comp className="text-green-70" {...props} />
              ),
            }}
            className="text-center"
          />
        )}
        {hasChildren && (
          <ul className="flex flex-wrap items-start justify-center -mt-10 -ml-8 lg:-ml-10">
            {children}
          </ul>
        )}
        {disclaimerTextHTML && (
          <HTMLContent
            html={disclaimerTextHTML}
            componentOverrides={{
              p: (Comp) => (props) => <Comp variant="sans-14" {...props} />,
              li: (Comp) => (props) => <Comp variant="sans-14" {...props} />,
              a: (Comp) => (props) => (
                <Comp
                  colorClassName="text-green-70"
                  hoverColorClassName="hover:text-yellow-50"
                  focusColorClassName="focus:text-yellow-50"
                  {...props}
                />
              ),
            }}
            className="text-center"
          />
        )}
      </div>
    </BoundedBox>
  )
}

type ItemProps = {
  descriptionHTML?: string
  buttonText?: string
  buttonHref?: string
  buttonTarget?: string
  buttonType?: 'Text' | 'Spotify'
}

const Item = ({
  descriptionHTML,
  buttonText = 'Learn more',
  buttonHref,
  buttonTarget,
  buttonType = 'Text',
}: ItemProps) => (
  <li className="pt-10 pl-8 lg:pl-10 md:w-4/12">
    <div className="grid gap-6 justify-items-center">
      {descriptionHTML && (
        <HTMLContent
          html={descriptionHTML}
          componentOverrides={{
            h1: (Comp) => (props) => <Comp variant="sans-24" {...props} />,
            p: (Comp) => (props) => <Comp variant="sans-16" {...props} />,
          }}
          className="text-center max-w-24rem md:max-w-none"
        />
      )}

      {buttonHref &&
        (buttonType === 'Spotify' ? (
          <Link href={buttonHref} target={buttonTarget}>
            <GatsbyImage
              fluid={spotifyButtonImageFluid}
              alt="Listen on Spotify"
              className="block w-8rem"
            />
          </Link>
        ) : (
          <ButtonLink
            variant="redSmall"
            href={buttonHref}
            target={buttonTarget}
          >
            <VisuallyHidden>Learn more by visiting {buttonHref}</VisuallyHidden>
            {buttonText}
          </ButtonLink>
        ))}
    </div>
  </li>
)

PageBodyLLContent.Item = Item

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyLlContentFragment, typeof mapDataToContext>) => ({
  introTextHTML: getRichText(data.primary?.intro_text),
  disclaimerTextHTML: getRichText(data.primary?.disclaimer_text),
  children: data.items?.map((item) => (
    <Item
      key={item?.button_link?.url}
      descriptionHTML={getRichText(item?.content_description)}
      buttonText={item?.button_text?.text}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
      buttonType={item?.button_type as 'Text' | 'Spotify'}
    />
  )) as React.ReactNode,
})

export const mapDataToContext = () => ({
  bg: 'bg-orange-60',
})

export const fragment = graphql`
  fragment PageBodyLLContent on PrismicPageBodyLLContent {
    primary {
      intro_text {
        text
        html
      }
      disclaimer_text {
        text
        html
      }
    }
    items {
      content_description {
        text
        html
      }
      button_text {
        text
      }
      button_link {
        url
        target
      }
      button_type
    }
  }
`

export default PageBodyLLContent
