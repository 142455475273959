import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { buildImgixFluid } from 'gatsby-plugin-imgix'

import { PageBodyHeroMenuItemFragment } from '../types.generated'
import { REGISTERED_MARK } from '../constants'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StandardGrid } from '../components/StandardGrid'
import { FancyHeading } from '../components/FancyHeading'
import { Text } from '../components/Text'
import { SuperscriptCharacter } from '../components/SuperscriptCharacter'

const starburstImageFluid = buildImgixFluid({
  url:
    'https://images.prismic.io/hawaiianbarbecue/ff7120cf-7f45-45c1-86ce-517eac8df1ca_texture-starburst.png',
  sourceWidth: 1508,
  sourceHeight: 1488,
  args: {
    imgixParams: {
      auto: 'compress,format',
      q: 50,
      w: 1200,
      fit: 'max',
    },
  },
})

export type PageBodyHeroMenuItemProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyHeroMenuItem = ({
  subheading,
  heading,
  imageFluid,
  imageAlt,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyHeroMenuItemProps) => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-lg"
    className="mx-auto text-gray-10 max-w-screen-xl bg-yellow-50"
  >
    <StandardGrid gapYClassName="gap-y-5" className="items-center">
      <div className="self-stretch -mx-6 -mb-10 overflow-hidden pointer-events-none select-none md:-mx-8 md:-mb-13 lg:-mx-16 lg:-mb-16 col-span-full row-start-2 md:col-span-6 md:row-start-1 md:col-start-7 -mt-6rem md:-mt-13 md:pt-13 lg:-mt-16 lg:pt-16 pt-6rem">
        <div className="relative h-full">
          <div className="absolute w-full px-3 md:px-0 mx-auto top-2/3 md:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-30rem md:max-w-none">
            <GatsbyImage fluid={starburstImageFluid} alt="" />
          </div>
        </div>
      </div>

      <div className="w-full mx-auto col-span-full row-start-2 md:col-span-6 md:row-start-1 md:col-start-7 -mb-22 md:-mt-13 md:-mb-23 lg:-mt-6rem lg:-mb-8rem max-w-24rem md:max-w-none">
        {imageFluid && <GatsbyImage fluid={imageFluid} alt={imageAlt} />}
      </div>

      <div className="relative col-span-full grid gap-6 lg:gap-7 row-start-1 md:col-span-6">
        {subheading && (
          <Text
            variant="sans-24"
            className="font-bold text-center text-teal-40 md:text-left"
          >
            {subheading}
          </Text>
        )}

        {heading && (
          <FancyHeading
            variant="redWhite"
            className="-ml-1 text-center md:text-left"
          >
            <SuperscriptCharacter character={REGISTERED_MARK}>
              {heading}
            </SuperscriptCharacter>
          </FancyHeading>
        )}
      </div>
    </StandardGrid>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyHeroMenuItemFragment,
  typeof mapDataToContext
>) => ({
  subheading: data.primary?.subheading?.text,
  heading: data.primary?.heading?.text,
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
})

export const mapDataToContext = () => ({
  bg: 'bg-yellow-50',
  overhangsBottom: [true, false],
})

export const fragment = graphql`
  fragment PageBodyHeroMenuItem on PrismicPageBodyHeroMenuItem {
    primary {
      subheading {
        text
      }
      heading {
        text
      }
      image {
        alt
        fluid(maxWidth: 500) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyHeroMenuItem
