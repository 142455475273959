import { useStaticQuery, graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'

import { UseNotificationMessageQuery } from '../types.generated'

export const useNotificationMessage = () => {
  const queryData = useStaticQuery<UseNotificationMessageQuery>(graphql`
    query UseNotificationMessage {
      prismicNotification {
        data {
          text {
            text
            html
          }
          is_active
        }
      }
    }
  `)

  const data = queryData.prismicNotification?.data

  return {
    text: data?.text?.text,
    textHTML: getRichText(data?.text),
    isActive: data?.is_active,
  }
}
