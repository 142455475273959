import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import * as Accordion from '@reach/accordion'
import clsx from 'clsx'

import { PageBodyAccordionFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { Text } from '../components/Text'
import { Icon } from '../components/Icon'

export type PageBodyAccordionProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyAccordion = ({
  introTextHTML,
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyAccordionProps): JSX.Element => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-screen-lg"
    className="mx-auto bg-white text-gray-10 max-w-screen-xl"
  >
    <div className="grid gap-5 md:gap-6">
      {introTextHTML && <StyledHTMLContent html={introTextHTML} />}
      <Accordion.Accordion collapsible={true} multiple={true}>
        {children}
      </Accordion.Accordion>
    </div>
  </BoundedBox>
)

type ItemProps = {
  index: number
  heading?: string
  textHTML?: string
}

const Item = ({ index, heading, textHTML }: ItemProps) => {
  const context = Accordion.useAccordionContext()
  const isOpen = context.openPanels.includes(index)

  return (
    <Accordion.AccordionItem className="border-b-2 border-gray-80">
      <Accordion.AccordionButton className="w-full block">
        <div className="justify-start w-full py-5 pl-px text-left grid gap-4 grid-flow-col">
          <Icon
            name="chevronDown"
            className={clsx(
              'transition transform w-4 pt-2px',
              isOpen && 'rotate-180',
            )}
          />
          <Text as="span" variant="sans-18" className="font-bold">
            {heading}
          </Text>
        </div>
      </Accordion.AccordionButton>
      <Accordion.AccordionPanel>
        <StyledHTMLContent html={textHTML} className="pt-4 pb-8" />
      </Accordion.AccordionPanel>
    </Accordion.AccordionItem>
  )
}

PageBodyAccordion.Item = Item

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyAccordionFragment, typeof mapDataToContext>) => ({
  introTextHTML: getRichText(data.primary?.intro_text),
  children: data.items?.map((item, index) => (
    <Item
      key={item?.heading?.text}
      index={index}
      heading={item?.heading?.text}
      textHTML={getRichText(item?.text)}
    />
  )) as React.ReactNode | undefined,
})

export const mapDataToContext = () => ({
  bg: 'bg-white',
})

export const fragment = graphql`
  fragment PageBodyAccordion on PrismicPageBodyAccordion {
    primary {
      intro_text {
        text
        html
      }
    }
    items {
      heading {
        text
      }
      text {
        text
        html
      }
    }
  }
`

export default PageBodyAccordion
