import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'
import clsx from 'clsx'
import VisuallyHidden from '@reach/visually-hidden'

import { PageBodyLargePromoFragment } from '../types.generated'
import {
  MapDataToContextArgs,
  MapDataToPropsArgs,
} from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { StandardGrid } from '../components/StandardGrid'
import { FancyHeading } from '../components/FancyHeading'
import { Text } from '../components/Text'

const variants = {
  Red: {
    backgroundColorClassName: 'bg-red-50',
    imageBackgroundColorClassName: 'bg-red-30',
    subheadingColorClassName: 'text-yellow-50',
    fancyHeadingVariant: 'yellow',
    buttonVariant: 'yellowLarge',
  },
  Teal: {
    backgroundColorClassName: 'bg-teal-40',
    imageBackgroundColorClassName: 'bg-teal-20',
    subheadingColorClassName: 'text-yellow-50',
    fancyHeadingVariant: 'yellow',
    buttonVariant: 'yellowLarge',
  },
} as const

const defaultVariant = 'Red'

export type PageBodyLargePromoProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyLargePromo = ({
  variant: variantName = defaultVariant,
  imageFluid,
  imageAlt,
  textHTML,
  buttonText = 'Learn more',
  buttonHref,
  buttonTarget,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyLargePromoProps): JSX.Element => {
  const variant = variants[variantName]

  return (
    <BoundedBox
      as="section"
      id={id}
      nextSharesBg={nextSharesBg}
      nextOverhangs={nextOverhangs}
      previousOverhangs={previousOverhangs}
      className={clsx(
        'text-white max-w-screen-xl mx-auto relative',
        variant.backgroundColorClassName,
      )}
    >
      <div
        className={clsx(
          '-mx-6 -mt-10 md:absolute md:top-0 md:left-0 md:bottom-0 md:w-1/2 md:mx-0 md:mt-0',
          variant.imageBackgroundColorClassName,
        )}
      >
        <div className="h-full overflow-hidden aspect-w-4 aspect-h-3 md:aspect-w-7 md:aspect-h-4">
          {imageFluid && (
            <div>
              <GatsbyImage
                fluid={imageFluid}
                alt={imageAlt ?? ''}
                className="h-full"
              />
            </div>
          )}
        </div>
      </div>

      <StandardGrid className="md:min-h-27rem">
        <div className="grid content-center gap-8 pt-10 col-span-full md:gap-10 lg:gap-12 justify-items-center md:pt-0 md:col-span-6 md:col-start-7 md:justify-items-start md:pl-4 lg:pl-8 xl:pl-20 md:max-w-45rem">
          {textHTML && (
            <HTMLContent
              html={textHTML}
              className="text-center md:text-left"
              componentOverrides={{
                h1: () => (props) => (
                  <FancyHeading
                    as="h1"
                    variant={variant.fancyHeadingVariant}
                    className="mb-7 md:mb-8 last:mb-0"
                    {...props}
                  />
                ),
                h2: () => (props) => (
                  <Text
                    variant="sans-24"
                    {...props}
                    className={clsx(
                      'font-bold mb-7 md:mb-8 last:mb-0',
                      variant.subheadingColorClassName,
                    )}
                  />
                ),
              }}
            />
          )}
          {buttonHref && (
            <ButtonLink
              href={buttonHref}
              target={buttonTarget}
              variant={variant.buttonVariant}
            >
              <VisuallyHidden>
                Learn more by visiting {buttonHref}
              </VisuallyHidden>
              {buttonText}
            </ButtonLink>
          )}
        </div>
      </StandardGrid>
    </BoundedBox>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyLargePromoFragment,
  typeof mapDataToContext
>) => ({
  variant: data.primary?.variant as keyof typeof variants | undefined,
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
  textHTML: getRichText(data?.primary?.text),
  buttonText: data.primary?.button_text?.text,
  buttonHref: data.primary?.button_link?.url,
  buttonTarget: data.primary?.button_link?.target,
})

export const mapDataToContext = ({
  data,
}: MapDataToContextArgs<PageBodyLargePromoFragment>) => {
  const variant =
    variants[(data.primary?.variant as keyof typeof variants) ?? defaultVariant]

  return {
    bgTop: Symbol(),
    bgBottom: [variant.backgroundColorClassName, Symbol()],
  }
}

export const fragment = graphql`
  fragment PageBodyLargePromo on PrismicPageBodyLargePromo {
    primary {
      variant
      image {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
      text {
        text
        html
      }
      button_link {
        url
        target
      }
      button_text {
        text
      }
    }
  }
`

export default PageBodyLargePromo
