import * as React from 'react'
import { useJsApiLoader as useGoogleMapsJsApiLoader } from '@react-google-maps/api'
import { debounce } from 'mini-debounce'
import { point, featureCollection, Feature } from '@turf/helpers'
import circle from '@turf/circle'
import nearestPoint from '@turf/nearest-point'
import distance from '@turf/distance'
import bboxPolygon from '@turf/bbox-polygon'
import bbox from '@turf/bbox'

import { useUserLocation } from './useUserLocation'
import { LocationFeature, useLocationsFeatures } from './useLocationFeatures'

const DEFAULT_MAP_UPDATE_DEBOUNCE_MS = 300
const SEARCH_BOUNDS_RADIUS_MILES = 3
const SEARCH_POINT_RADIUS_MILES = 5

export enum LocationsMapFilterType {
  Delivery = 'Delivery',
  GiftCards = 'GiftCards',
  OnlineOrdering = 'OnlineOrdering',
  OnApp = 'OnApp',
}

enum ActionType {
  ApiIsReady = 'ApiIsReady',
  MountedMap = 'MountedMap',
  UnmountedMap = 'UnmountedMap',
  MapBoundsChanged = 'MapBoundsChanged',
  SelectLocation = 'SelectLocation',
  DeselectLocation = 'DeselectLocation',
  UpdateSearchQuery = 'UpdateSearchQuery',
  EnableFilter = 'AddFilter',
  DisableFilter = 'RemoveFilter',
}

type Action =
  | {
      type: ActionType.ApiIsReady
    }
  | {
      type: ActionType.MountedMap
      payload: { map: google.maps.Map }
    }
  | {
      type: ActionType.UnmountedMap
    }
  | {
      type: ActionType.MapBoundsChanged
      payload: { bounds: google.maps.LatLngBounds }
    }
  | {
      type: ActionType.SelectLocation
      payload: { locationUID: string }
    }
  | {
      type: ActionType.DeselectLocation
    }
  | {
      type: ActionType.UpdateSearchQuery
      payload: { searchQuery: string }
    }
  | {
      type: ActionType.EnableFilter
      payload: { type: LocationsMapFilterType }
    }
  | {
      type: ActionType.DisableFilter
      payload: { type: LocationsMapFilterType }
    }

interface State {
  isReady: boolean
  map: google.maps.Map | undefined
  mapBounds: google.maps.LatLngBounds | undefined
  selectedLocationUID: string | undefined
  searchQuery: string
  filterDelivery: boolean
  filterGiftCards: boolean
  filterOnlineOrdering: boolean
  filterOnApp: boolean
}

const initialState: State = {
  isReady: false,
  map: undefined,
  mapBounds: undefined,
  selectedLocationUID: undefined,
  searchQuery: '',
  filterDelivery: false,
  filterGiftCards: false,
  filterOnlineOrdering: false,
  filterOnApp: false,
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ApiIsReady: {
      return {
        ...state,
        isReady: true,
      }
    }

    case ActionType.MountedMap: {
      return {
        ...state,
        map: action.payload.map,
      }
    }

    case ActionType.UnmountedMap: {
      return initialState
    }

    case ActionType.MapBoundsChanged: {
      return {
        ...state,
        mapBounds: action.payload.bounds,
      }
    }

    case ActionType.SelectLocation: {
      return {
        ...state,
        selectedLocationUID: action.payload.locationUID,
      }
    }

    case ActionType.DeselectLocation: {
      return {
        ...state,
        selectedLocationUID: undefined,
      }
    }

    case ActionType.UpdateSearchQuery: {
      return {
        ...state,
        searchQuery: action.payload.searchQuery,
      }
    }

    case ActionType.EnableFilter: {
      switch (action.payload.type) {
        case LocationsMapFilterType.Delivery:
          return { ...state, filterDelivery: true }

        case LocationsMapFilterType.GiftCards:
          return { ...state, filterGiftCards: true }

        case LocationsMapFilterType.OnlineOrdering:
          return { ...state, filterOnlineOrdering: true }

        case LocationsMapFilterType.OnApp:
          return { ...state, filterOnApp: true }
      }
    }

    case ActionType.DisableFilter: {
      switch (action.payload.type) {
        case LocationsMapFilterType.Delivery:
          return { ...state, filterDelivery: false }

        case LocationsMapFilterType.GiftCards:
          return { ...state, filterGiftCards: false }

        case LocationsMapFilterType.OnlineOrdering:
          return { ...state, filterOnlineOrdering: false }

        case LocationsMapFilterType.OnApp:
          return { ...state, filterOnApp: false }
      }
    }
  }
}

const sortLocationFeaturesByDistance = (
  locationFeatures: LocationFeature[],
  fromLatitude: number,
  fromLongitude: number,
): LocationFeature[] => {
  const centerPoint = point([fromLongitude, fromLatitude])

  return locationFeatures.sort(
    (a, b) => distance(a, centerPoint) - distance(b, centerPoint),
  )
}

type FilterLocationFeaturesFilters = {
  filterDelivery: boolean
  filterGiftCards: boolean
  filterOnlineOrdering: boolean
  filterOnApp: boolean
}

const filterLocationFeatures = (
  locationFeatures: LocationFeature[],
  filters: FilterLocationFeaturesFilters,
): LocationFeature[] => {
  const hasFilters =
    filters.filterDelivery ||
    filters.filterGiftCards ||
    filters.filterOnlineOrdering ||
    filters.filterOnApp

  if (hasFilters) {
    return locationFeatures.filter((locationFeature) => {
      let result = true

      if (filters.filterDelivery) {
        result = result && locationFeature.properties.providesDelivery
      }

      if (filters.filterGiftCards) {
        result = result && locationFeature.properties.acceptsGiftCards
      }

      if (filters.filterOnlineOrdering) {
        result = result && locationFeature.properties.providesOnlineOrdering
      }

      if (filters.filterOnApp) {
        result = result && locationFeature.properties.isOnApp
      }

      return result
    })
  } else {
    return locationFeatures
  }
}

const prepareLocationFeatures = (
  locationFeatures: LocationFeature[],
  sortFromLatitude: number,
  sortFromLongitude: number,
  filters: FilterLocationFeaturesFilters,
): LocationFeature[] =>
  sortLocationFeaturesByDistance(
    filterLocationFeatures(locationFeatures, filters),
    sortFromLatitude,
    sortFromLongitude,
  )

type UseLocationsMapConfig = {
  id?: string
  googleMapsApiKey?: string
  mapUpdateDebounceMs?: number
}

export const useLocationsMap = (config: UseLocationsMapConfig = {}) => {
  const {
    id = 'use-locations-maps',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    googleMapsApiKey = process.env.GATSBY_GOOGLE_MAPS_API_KEY!,
    mapUpdateDebounceMs = DEFAULT_MAP_UPDATE_DEBOUNCE_MS,
  } = config

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const { isLoaded } = useGoogleMapsJsApiLoader({ id, googleMapsApiKey })
  const locationFeatures = useLocationsFeatures()
  const userLocation = useUserLocation(true)

  React.useEffect(() => {
    if (isLoaded) {
      dispatch({ type: ActionType.ApiIsReady })
    }
  }, [isLoaded])

  const onMapLoad = React.useCallback((map: google.maps.Map) => {
    dispatch({ type: ActionType.MountedMap, payload: { map } })
  }, [])

  const onMapUnmount = React.useCallback(() => {
    dispatch({ type: ActionType.UnmountedMap })
  }, [])

  const onBoundsChanged = React.useCallback(() => {
    const bounds = state.map?.getBounds()

    if (bounds != null) {
      dispatch({ type: ActionType.MapBoundsChanged, payload: { bounds } })
    }
  }, [state.map])

  const onSearchQueryChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: ActionType.UpdateSearchQuery,
        payload: { searchQuery: event.currentTarget.value },
      })
    },
    [],
  )

  const onSearchFormSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      if (state.map && state.searchQuery) {
        const Geocoder = new window.google.maps.Geocoder()

        Geocoder.geocode({ address: state.searchQuery }, (results, status) => {
          if (status !== google.maps.GeocoderStatus.OK) {
            return
          }

          const result = results[0]

          // We will build up a list of features (i.e. geometric areas or
          // points) that will be combined into one large bbox.
          let features: Feature[] = []

          if (result.geometry.bounds) {
            // Get the bounds of the searched location.
            const resultBoundsNorthEast = result.geometry.bounds.getNorthEast()
            const resultBoundsSouthWest = result.geometry.bounds.getSouthWest()
            const resultBboxPolygon = bboxPolygon([
              resultBoundsNorthEast.lng(),
              resultBoundsNorthEast.lat(),
              resultBoundsSouthWest.lng(),
              resultBoundsSouthWest.lat(),
            ])

            // Get a SEARCH_BOUNDS_RADIUS_MILES mile radius from the center of
            // the searched location. This may be larger or smaller than the
            // bounds, but gives us a reasonable minimum zoom level.
            const resultCenter = result.geometry.bounds.getCenter()
            const resultCenterPoint = point([
              resultCenter.lng(),
              resultCenter.lat(),
            ])
            const resultCircle = circle(
              resultCenterPoint,
              SEARCH_BOUNDS_RADIUS_MILES,
              { units: 'miles' },
            )

            features = [...features, resultBboxPolygon, resultCircle]
          } else if (result.geometry.location) {
            // If the searched location is a specific point, and not an
            // area/bound, we just take a SEARCH_POINT_RADIUS_MILES mile radius
            // from the point.
            const resultCenter = result.geometry.location
            const resultCenterPoint = point([
              resultCenter.lng(),
              resultCenter.lat(),
            ])
            const resultCircle = circle(
              resultCenterPoint,
              SEARCH_POINT_RADIUS_MILES,
              { units: 'miles' },
            )

            features = [...features, resultCircle]
          }

          const newBbox = bbox(featureCollection(features))
          const newBounds: google.maps.LatLngBoundsLiteral = {
            east: newBbox[2],
            north: newBbox[3],
            south: newBbox[1],
            west: newBbox[0],
          }

          state.map?.fitBounds(newBounds)
        })
      }
    },
    [state.map, state.searchQuery],
  )

  const selectLocation = React.useCallback((locationUID: string) => {
    dispatch({ type: ActionType.SelectLocation, payload: { locationUID } })
  }, [])

  const deselectLocation = React.useCallback(() => {
    dispatch({ type: ActionType.DeselectLocation })
  }, [])

  const enableFilter = React.useCallback((type: LocationsMapFilterType) => {
    dispatch({ type: ActionType.EnableFilter, payload: { type } })
  }, [])

  const disableFilter = React.useCallback((type: LocationsMapFilterType) => {
    dispatch({ type: ActionType.DisableFilter, payload: { type } })
  }, [])

  const scopedLocationFeatures = React.useMemo(() => {
    if (state.mapBounds) {
      const center = state.mapBounds.getCenter()

      // Sort by distance from current position.  If unavailable, use the map
      // center.
      const latitude =
        userLocation.state === 'resolved'
          ? userLocation.coords.latitude
          : center.lat()
      const longitude =
        userLocation.state === 'resolved'
          ? userLocation.coords.longitude
          : center.lng()

      return prepareLocationFeatures(locationFeatures, latitude, longitude, {
        filterDelivery: state.filterDelivery,
        filterGiftCards: state.filterGiftCards,
        filterOnlineOrdering: state.filterOnlineOrdering,
        filterOnApp: state.filterOnApp,
      })
    } else {
      return []
    }
  }, [
    locationFeatures,
    userLocation.state,
    state.mapBounds,
    state.filterDelivery,
    state.filterGiftCards,
    state.filterOnlineOrdering,
    state.filterOnApp,
  ])

  const scopedLocationFeaturesOnMap = React.useMemo(() => {
    if (state.mapBounds) {
      return scopedLocationFeatures.filter((locationFeature) =>
        state.mapBounds?.contains({
          lat: locationFeature.geometry.coordinates[1],
          lng: locationFeature.geometry.coordinates[0],
        }),
      )
    } else {
      return []
    }
  }, [scopedLocationFeatures, state.mapBounds])

  const centerOnNearestLocation = React.useCallback(() => {
    if (userLocation.state === 'resolved') {
      const positionPointFeature = point([
        userLocation.coords.longitude,
        userLocation.coords.latitude,
      ])
      const preparedLocationFeatureCollection = featureCollection(
        scopedLocationFeatures,
      )

      const nearestPreparedLocation = nearestPoint(
        positionPointFeature,
        preparedLocationFeatureCollection,
      )

      if (
        nearestPreparedLocation.geometry.coordinates[1] &&
        nearestPreparedLocation.geometry.coordinates[0]
      ) {
        state.map?.setZoom(16)
        state.map?.panTo({
          lat: nearestPreparedLocation.geometry.coordinates[1],
          lng: nearestPreparedLocation.geometry.coordinates[0],
        })
      }
    }
  }, [state.map, userLocation.state, scopedLocationFeatures])

  return React.useMemo(
    () =>
      [
        state,
        {
          scopedLocationFeatures,
          scopedLocationFeaturesOnMap,
        },
        {
          onMapLoad,
          onMapUnmount,
          onBoundsChanged: debounce(onBoundsChanged, mapUpdateDebounceMs),
          onSearchQueryChanged,
          onSearchFormSubmit,
          selectLocation,
          deselectLocation,
          enableFilter,
          disableFilter,
          centerOnNearestLocation,
        },
      ] as const,
    [
      state,
      onMapLoad,
      onMapUnmount,
      onBoundsChanged,
      onSearchQueryChanged,
      onSearchFormSubmit,
      selectLocation,
      deselectLocation,
      enableFilter,
      disableFilter,
      centerOnNearestLocation,
      scopedLocationFeatures,
      scopedLocationFeaturesOnMap,
      mapUpdateDebounceMs,
    ],
  )
}
