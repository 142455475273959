import * as React from 'react'
import clsx from 'clsx'

import { Text, TextProps } from './Text'

const defaultElement = 'p'

const variants = {
  yellow: {
    colorClassName: 'text-yellow-50',
    textShadowClassname: 'text-shadow-heading',
    textVariant: 'sans-48-80',
  },
  yellowSmall: {
    colorClassName: 'text-yellow-50',
    textShadowClassname: 'text-shadow-heading',
    textVariant: 'sans-48-64',
  },
  yellowWhite: {
    colorClassName: 'text-yellow-50',
    textShadowClassname: 'text-shadow-heading-white',
    textVariant: 'sans-48-80',
  },
  red: {
    colorClassName: 'text-red-50',
    textShadowClassname: 'text-shadow-heading',
    textVariant: 'sans-48-80',
  },
  redYellow: {
    colorClassName: 'text-red-50',
    textShadowClassname: 'text-shadow-heading-yellow-50',
    textVariant: 'sans-48-80',
  },
  redYellowSmall: {
    colorClassName: 'text-red-50',
    textShadowClassname: 'text-shadow-heading-yellow-50',
    textVariant: 'sans-48-64',
  },
  redYellowLarge: {
    colorClassName: 'text-red-50',
    textShadowClassname: 'text-shadow-heading-yellow-50',
    textVariant: 'sans-64-110',
  },
  redWhite: {
    colorClassName: 'text-red-50',
    textShadowClassname: 'text-shadow-heading-white',
    textVariant: 'sans-48-80',
  },
  redWhiteSmall: {
    colorClassName: 'text-red-50',
    textShadowClassname: 'text-shadow-heading-white',
    textVariant: 'sans-48-64',
  },
  redWhiteLarge: {
    colorClassName: 'text-red-50',
    textShadowClassname: 'text-shadow-heading-white',
    textVariant: 'sans-64-110',
  },
  whiteRed: {
    colorClassName: 'text-white',
    textShadowClassname: 'text-shadow-heading-red',
    textVariant: 'sans-48-80',
  },
} as const

type FancyHeadingProps<E extends React.ElementType = typeof defaultElement> = {
  variant: keyof typeof variants
  textVariant?: TextProps['variant']
} & Omit<TextProps<E>, 'variant'>

export const FancyHeading = <
  E extends React.ElementType = typeof defaultElement
>({
  as,
  variant: variantName = 'yellow',
  textVariant,
  className,
  ...restProps
}: FancyHeadingProps<E>) => {
  const resolvedAs: React.ElementType = as ?? defaultElement
  const variant = variants[variantName]

  return (
    <Text
      as={resolvedAs}
      variant={textVariant ?? variant.textVariant}
      {...restProps}
      className={clsx(
        'font-bold tracking-wide uppercase',
        variant.colorClassName,
        variant.textShadowClassname,
        className,
      )}
    />
  )
}
