import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText, undefIfEmpty } from '@walltowall/helpers'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import VisuallyHidden from '@reach/visually-hidden'
import clsx from 'clsx'

import { PageBodyHighlightsFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { FancyHeading } from '../components/FancyHeading'
import { Text } from '../components/Text'
import { StyledHTMLContent } from '../components/StyledHTMLContent'

export type PageBodyHighlightsProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyHighlights = ({
  introTextHTML,
  buttonText = 'Learn More',
  buttonHref,
  buttonTarget,
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
}: PageBodyHighlightsProps): JSX.Element => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-70rem"
    className="max-w-screen-xl mx-auto bg-yellow-50 text-gray-10"
  >
    <div className="grid gap-12 lg:gap-15 justify-items-center">
      {introTextHTML && (
        <StyledHTMLContent
          html={introTextHTML}
          componentOverrides={{
            h1: () => (props) => (
              <FancyHeading
                as="h1"
                variant="redWhiteSmall"
                {...props}
                className="mb-7 md:mb-8 last:mb-0"
              />
            ),
            h2: (Comp) => (props) => (
              <Comp className="text-red-50" {...props} />
            ),
          }}
          className="max-w-screen-md mx-auto text-center"
        />
      )}
      {children && (
        <ul className="flex flex-wrap justify-center -mt-6 -ml-6 md:-mt-8 lg:-mt-12 md:-ml-8 lg:-ml-12">
          {children}
        </ul>
      )}
      {buttonHref && (
        <ButtonLink variant="tealLarge" href={buttonHref} target={buttonTarget}>
          <VisuallyHidden>Learn more by visiting {buttonHref}</VisuallyHidden>
          {buttonText}
        </ButtonLink>
      )}
    </div>
  </BoundedBox>
)

type HighlightProps = {
  textHTML?: string
  imageFluid?: FluidObject
  imageAlt?: string
}

const Highlight = ({ textHTML, imageFluid, imageAlt }: HighlightProps) => (
  <li className="w-full pt-6 pl-6 md:w-6/12 lg:w-4/12 md:pl-8 lg:pl-12 md:pt-8 lg:pt-12">
    <div
      className={clsx(
        'h-full overflow-hidden shadow-lg grid',
        imageFluid && 'grid-rows-auto-1fr',
      )}
    >
      {imageFluid && (
        <div className="aspect-w-1 aspect-h-1">
          <div>
            <GatsbyImage fluid={imageFluid} alt={imageAlt} className="h-full" />
          </div>
        </div>
      )}
      <div className="p-8 pb-10 bg-white text-gray-10 md:pb-13">
        {textHTML && (
          <HTMLContent
            html={textHTML}
            componentOverrides={{
              h3: () => (props) => (
                <Text
                  as="h2"
                  variant="sans-24"
                  {...props}
                  className="font-bold tracking-wide text-center uppercase text-teal-40 mb-7 md:mb-8"
                />
              ),
              p: (Comp) => (props) => <Comp variant="sans-16" {...props} />,
              li: (Comp) => (props) => (
                <Comp variant="sans-16" className="text-left" {...props} />
              ),
            }}
            className="text-center"
          />
        )}
      </div>
    </div>
  </li>
)

PageBodyHighlights.Highlight = Highlight

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyHighlightsFragment,
  typeof mapDataToContext
>) => ({
  introTextHTML: getRichText(data.primary?.intro_text),
  buttonText: data.primary?.button_text?.text,
  buttonHref: data.primary?.button_link?.url,
  buttonTarget: undefIfEmpty(data.primary?.button_link?.target),
  children: data.items?.map((item) => (
    <Highlight
      key={item?.text?.text}
      textHTML={getRichText(item?.text)}
      imageFluid={item?.image?.fluid}
      imageAlt={item?.image?.alt}
    />
  )) as React.ReactNode | undefined,
})

export const mapDataToContext = () => ({
  bg: 'bg-yellow-50',
})

export const fragment = graphql`
  fragment PageBodyHighlights on PrismicPageBodyHighlights {
    primary {
      intro_text {
        text
        html
      }
      button_text {
        text
      }
      button_link {
        url
        target
      }
    }
    items {
      image {
        alt
        fluid(maxWidth: 700) {
          ...GatsbyPrismicImageFluid
        }
      }
      text {
        text
        html
      }
    }
  }
`

export default PageBodyHighlights
