import * as React from 'react'
import { useLayoutEffect } from '@radix-ui/react-use-layout-effect'

let globalCount = 0

export function useIncrementId(providedId: string) {
  const [count, setCount] = React.useState(globalCount)

  useLayoutEffect(() => {
    setCount(globalCount++)
  }, [])

  return `${providedId}-${count}`
}
