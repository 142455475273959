import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import clsx from 'clsx'
import VisuallyHidden from '@reach/visually-hidden'

import { PageBodyPromoBoxesFragment } from '../types.generated'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { StandardGrid } from '../components/StandardGrid'
import { StyledHTMLContent } from '../components/StyledHTMLContent'

import { ReactComponent as AssetTextureDotsSVG } from '../assets/texture-dots.svg'

export type PageBodyPromoBoxesProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyPromoBoxes = ({
  children,
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
  nextIsFooter,
}: PageBodyPromoBoxesProps): JSX.Element => (
  <BoundedBox
    as="section"
    id={id}
    nextSharesBg={nextSharesBg}
    nextOverhangs={nextOverhangs}
    previousOverhangs={previousOverhangs}
    innerMaxWidthClassName="max-w-70rem"
    ptClassName="pt-0"
    pbClassName={nextIsFooter ? undefined : 'pb-0'}
    className="max-w-screen-xl mx-auto bg-white"
  >
    <div className="flex flex-col">
      <ul
        className={clsx(
          '-mx-6 bg-white md:-mx-0 md:shadow-xl lg:shadow-2xl md:relative md:z-10 md:-mt-9 lg:-mt-12 bg-debug',
          !nextIsFooter && 'md:-mb-9 lg:-mb-12',
        )}
      >
        {children}
      </ul>
    </div>
  </BoundedBox>
)

type PromoBoxProps = {
  index: number
  textHTML?: string
  imageFluid?: FluidObject
  imageAlt?: string
  buttonText?: string
  buttonHref?: string
  buttonTarget?: string
}

const PromoBox = ({
  index,
  textHTML,
  imageFluid,
  imageAlt,
  buttonHref,
  buttonText = 'Learn more',
  buttonTarget,
}: PromoBoxProps) => {
  const imageIsOnRight = index % 2 === 1

  return (
    <li className="border-b-2 border-gray-80 last:border-b-0">
      <StandardGrid gapXClassName="gap-0" className="grid-flow-row-dense">
        <div
          className={clsx(
            'col-span-full md:col-span-4',
            imageIsOnRight && 'md:col-start-9',
          )}
        >
          <div className="h-full aspect-w-3 aspect-h-2 bg-gray-60 md:aspect-w-1 md:aspect-h-1">
            <div>
              {imageFluid && (
                <GatsbyImage
                  fluid={imageFluid}
                  alt={imageAlt}
                  className="h-full"
                />
              )}
            </div>
          </div>
        </div>

        <BoundedBox
          className={clsx(
            'bg-white text-gray-10 col-span-full md:col-span-8 relative overflow-hidden',
            imageIsOnRight && 'md:col-start-1',
          )}
        >
          <AssetTextureDotsSVG
            className={clsx(
              'absolute w-1/3 -left-1/4 md:left-0 -top-1/2 transform rotate-30 text-yellow-50',
              imageIsOnRight ? 'md:-translate-x-1/2' : 'md:-translate-y-1/4',
            )}
          />
          <AssetTextureDotsSVG className="absolute w-1/3 transform -right-1/4 md:-right-1/4 -bottom-1/3 md:bottom-0 rotate-30 text-yellow-50" />

          <div className="relative grid content-center h-full gap-7 md:gap-8 justify-items-center md:justify-items-start md:max-w-40rem md:ml-auto">
            {textHTML && (
              <StyledHTMLContent
                html={textHTML}
                className="text-center md:text-left max-w-50ch"
              />
            )}
            {buttonHref && (
              <ButtonLink
                variant="tealLarge"
                href={buttonHref}
                target={buttonTarget}
              >
                <VisuallyHidden>
                  Learn more by visiting {buttonHref}
                </VisuallyHidden>
                {buttonText}
              </ButtonLink>
            )}
          </div>
        </BoundedBox>
      </StandardGrid>
    </li>
  )
}

PageBodyPromoBoxes.PromoBox = PromoBox

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyPromoBoxesFragment,
  typeof mapDataToContext
>) => ({
  children: data.items?.map((item, index) => (
    <PromoBox
      key={item?.text?.text}
      index={index}
      textHTML={getRichText(item?.text)}
      imageFluid={item?.image?.fluid}
      imageAlt={item?.image?.alt}
      buttonText={item?.button_text?.text}
      buttonHref={item?.button_link?.url}
      buttonTarget={item?.button_link?.target}
    />
  )) as React.ReactNode | undefined,
})

export const mapDataToContext = () => ({
  bgTop: [Symbol(), 'bg-white'],
  bgBottom: 'bg-white',
  overhangsBottom: [false, true],
  overhangsTop: [false, true],
})

export const fragment = graphql`
  fragment PageBodyPromoBoxes on PrismicPageBodyPromoBoxes {
    items {
      image {
        alt
        fluid(maxWidth: 500) {
          ...GatsbyPrismicImageFluid
        }
      }
      text {
        text
        html
      }
      button_text {
        text
      }
      button_link {
        url
        target
      }
    }
  }
`

export default PageBodyPromoBoxes
