/**
 * Note: This Slice is used only within the project and is not made available
 * to editors in Prismic.
 */

import * as React from 'react'
import { GoogleMap, Marker, InfoBox } from '@react-google-maps/api'
import { Coord } from '@turf/helpers'
import distance from '@turf/distance'
import VisuallyHidden from '@reach/visually-hidden'
import clsx from 'clsx'

import { PageTemplateEnhancerProps } from '../templates/page'
import {
  LocationsMapFilterType,
  useLocationsMap,
} from '../hooks/useLocationsMap'

import { BoundedBox } from '../components/BoundedBox'
import { Button } from '../components/Button'
import { Icon, IconProps } from '../components/Icon'
import { Text } from '../components/Text'
import { LocationCard } from '../components/LocationCard'
import { Anchor } from '../components/Anchor'
import { Address } from '../components/Address'
import { ButtonLink } from '../components/ButtonLink'
import { useUserLocation } from '../hooks/useUserLocation'
import { match } from 'ts-pattern'

const MAP_DEFAULT_CENTER: google.maps.LatLngLiteral = {
  lat: 21.4645213,
  lng: -157.9560242,
}

const MAP_CONTAINER_STYLE: React.CSSProperties = {
  height: '100%',
  width: '100%',
}

const MAP_OPTIONS: google.maps.MapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  zoom: 11,
  minZoom: 5,
  maxZoom: 17,
  center: MAP_DEFAULT_CENTER,
}

type LocationMarkerProps = {
  title?: string
  href: string
  label: string
  lat: number
  lng: number
  streetAddress?: string
  state?: string
  city?: string
  zipCode?: string
  country?: string
  isActive: boolean
  setActive: () => void
}

const LocationMarker = ({
  title,
  href,
  label,
  lat,
  lng,
  streetAddress,
  city,
  state,
  zipCode,
  country,
  isActive,
  setActive,
}: LocationMarkerProps) => (
  <div className={clsx(isActive && 'relative z-10')}>
    <Marker
      label={{
        text: label,
        color: 'white',
        fontSize: '13',
        className: 'font-extrabold',
      }}
      position={{ lat, lng }}
      onClick={() => setActive()}
    />
    {isActive && (
      <InfoBox
        position={{ lat, lng }}
        options={{ closeBoxURL: '', boxClass: 'shadow-md mt-1 ml-1' }}
      >
        <div className="grid gap-4 p-4 border-t-8 bg-yellow-50 text-gray-10 w-12rem border-red-50">
          <Text variant="sans-16" className="font-bold">
            <Anchor
              href={href}
              colorClassName="text-red-50"
              hoverColorClassName="hover:text-teal-40"
              focusColorClassName="focus:text-teal-40"
            >
              {title}
            </Anchor>
          </Text>

          <Text variant="sans-13">
            <Address
              streetAddress={streetAddress}
              city={city}
              state={state}
              zipCode={zipCode}
              country={country}
            />
          </Text>

          <ButtonLink variant="tealSmall" href={href}>
            View details
          </ButtonLink>
        </div>
      </InfoBox>
    )}
  </div>
)

type FilterCheckboxProps = {
  name: string
  iconName: IconProps['name']
  label: string
  checked?: boolean
  onChange?: React.FormEventHandler<HTMLInputElement>
  type: LocationsMapFilterType
  enableFilter: (type: LocationsMapFilterType) => void
  disableFilter: (type: LocationsMapFilterType) => void
}

const FilterCheckbox = ({
  name,
  iconName,
  label,
  type,
  checked = false,
  enableFilter,
  disableFilter,
}: FilterCheckboxProps) => (
  <li>
    <label className="grid justify-start grid-flow-col gap-3">
      <input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={(event) =>
          event.currentTarget.checked ? enableFilter(type) : disableFilter(type)
        }
        className="w-5 h-5 bg-white border-0 rounded"
      />
      <div className="grid items-center grid-flow-col gap-2">
        <div className="flex items-center justify-center w-5 h-5">
          <Icon name={iconName} className="w-full h-full" />
        </div>
        <Text as="span" variant="sans-18">
          {label}
        </Text>
      </div>
    </label>
  </li>
)

export type PageBodyLocationsProps = PageTemplateEnhancerProps

const PageBodyLocations = ({
  id,
  nextSharesBg,
  nextOverhangs,
  previousOverhangs,
  previousIsHeader,
}: PageBodyLocationsProps) => {
  const [mapState, mapDerivedState, mapActions] = useLocationsMap()
  const userLocation = useUserLocation(true)

  const hasPosition = match(userLocation.state)
    .with('resolved', () => true)
    .otherwise(() => false)

  const distanceFromCurrentPosition = (
    coordinates: Coord,
  ): string | undefined => {
    if (userLocation.state !== 'resolved') {
      return
    }

    const d = distance(
      coordinates,
      [userLocation.coords.longitude, userLocation.coords.latitude],
      { units: 'miles' },
    )

    return d < 2 ? `${d.toFixed(1)} mi away` : `${d.toFixed()} mi away`
  }

  return (
    <>
      <BoundedBox
        as="section"
        id={id}
        nextSharesBg={[true, false]}
        className="relative max-w-screen-xl mx-auto bg-white text-gray-10"
      >
        <div className="-mx-6 -mt-10 md:my-0 md:-ml-8 lg:ml-0 md:mx-0 md:relative md:z-10 md:shadow-xl lg:shadow-2xl md:w-21rem lg:w-27rem">
          <form action="" method="get" onSubmit={mapActions.onSearchFormSubmit}>
            <div
              className={clsx(
                'p-6 md:px-9 md:py-10 bg-yellow-50 text-gray-10',
                previousOverhangs && 'pt-11',
                previousIsHeader && 'pt-9',
              )}
            >
              <div className="grid gap-5">
                <Text
                  as="h1"
                  variant="sans-35-40"
                  className="font-extrabold uppercase text-red-50"
                >
                  Find a Restaurant
                </Text>

                <Text as="p" variant="sans-18" className="max-w-50ch">
                  We have locations all around the globe! Find the nearest L&L
                  restaurant by searching below or view all locations.
                </Text>

                {hasPosition && (
                  <>
                    <Button
                      type="button"
                      onClick={mapActions.centerOnNearestLocation}
                    >
                      Find Nearby Location
                    </Button>

                    <Text
                      variant="sans-24"
                      className="font-bold text-center uppercase"
                    >
                      or
                    </Text>
                  </>
                )}

                <div className="flex md:flex-col lg:flex-row">
                  <label className="flex-grow">
                    <VisuallyHidden>
                      <span>Search</span>
                    </VisuallyHidden>
                    <input
                      name="searchQuery"
                      placeholder="City, State or Zip Code"
                      onChange={mapActions.onSearchQueryChanged}
                      className="w-full min-w-0 px-4 py-3 font-sans placeholder-gray-60 text-15 md:text-18 text-gray-10"
                    />
                  </label>
                  <Button
                    type="submit"
                    minWidthClassName="min-w-0"
                    paddingClassName="py-4 px-3 md:px-4"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
            <div className="p-6 md:px-9 md:py-8 bg-orange-50 text-gray-10">
              <div className="grid gap-6">
                <Text
                  as="h2"
                  variant="sans-18"
                  className="font-bold tracking-wide text-white uppercase md:text-center"
                >
                  Filter Locations
                </Text>

                <ul className="grid items-start gap-3 lg:gap-x-6 lg:grid-cols-auto-1fr">
                  <FilterCheckbox
                    name="provides_delivery"
                    iconName="locationCardDelivery"
                    label="Delivery"
                    checked={mapState.filterDelivery}
                    type={LocationsMapFilterType.Delivery}
                    enableFilter={mapActions.enableFilter}
                    disableFilter={mapActions.disableFilter}
                  />
                  <FilterCheckbox
                    name="provides_online_ordering"
                    iconName="locationCardOnlineOrdering"
                    label="Online Ordering"
                    checked={mapState.filterOnlineOrdering}
                    type={LocationsMapFilterType.OnlineOrdering}
                    enableFilter={mapActions.enableFilter}
                    disableFilter={mapActions.disableFilter}
                  />
                  <FilterCheckbox
                    name="accepts_gift_cards"
                    iconName="locationCardGiftCard"
                    label="Gift Cards"
                    checked={mapState.filterGiftCards}
                    type={LocationsMapFilterType.GiftCards}
                    enableFilter={mapActions.enableFilter}
                    disableFilter={mapActions.disableFilter}
                  />
                  {/* TODO: Uncomment once LHB is ready to allow filtering by app presence.
                  <FilterCheckbox
                    name="on_app"
                    iconName="phone"
                    label="On the L&L App"
                    checked={mapState.filterOnApp}
                    type={LocationsMapFilterType.OnApp}
                    enableFilter={mapActions.enableFilter}
                    disableFilter={mapActions.disableFilter}
                  />
                  */}
                </ul>
              </div>
            </div>
          </form>
        </div>

        <div className="-mx-6 md:inset-0 md:mx-0 bg-gray-60 md:absolute aspect-w-4 aspect-h-3 md:aspect-none">
          <div className="flex md:h-full">
            <div className="flex-grow">
              {mapState.isReady ? (
                <GoogleMap
                  mapContainerStyle={MAP_CONTAINER_STYLE}
                  options={MAP_OPTIONS}
                  onLoad={mapActions.onMapLoad}
                  onUnmount={mapActions.onMapUnmount}
                  onBoundsChanged={mapActions.onBoundsChanged}
                  onDragStart={mapActions.deselectLocation}
                  onClick={mapActions.deselectLocation}
                >
                  {mapDerivedState.scopedLocationFeaturesOnMap.map(
                    (locationFeature, i) => (
                      <LocationMarker
                        key={locationFeature.properties.href}
                        label={(i + 1).toString()}
                        title={locationFeature.properties.title}
                        href={locationFeature.properties.href}
                        lat={locationFeature.geometry.coordinates[1]}
                        lng={locationFeature.geometry.coordinates[0]}
                        streetAddress={locationFeature.properties.streetAddress}
                        city={locationFeature.properties.city}
                        state={locationFeature.properties.state}
                        zipCode={locationFeature.properties.zipCode}
                        country={locationFeature.properties.country}
                        isActive={
                          mapState.selectedLocationUID ===
                          locationFeature.properties.uid
                        }
                        setActive={() =>
                          mapActions.selectLocation(
                            locationFeature.properties.uid,
                          )
                        }
                      />
                    ),
                  )}
                </GoogleMap>
              ) : (
                'Loading'
              )}
            </div>
          </div>
        </div>
      </BoundedBox>
      <BoundedBox
        as="section"
        nextSharesBg={nextSharesBg}
        nextOverhangs={nextOverhangs}
        innerMaxWidthClassName="max-w-screen-lg xl:max-w-none"
        className="relative max-w-screen-xl mx-auto bg-white text-gray-10"
      >
        {mapDerivedState.scopedLocationFeaturesOnMap.length > 0 ? (
          <ul className="grid gap-5 md:gap-6 md:grid-cols-2 xl:grid-cols-3">
            {mapDerivedState.scopedLocationFeaturesOnMap.map(
              (locationFeature, i) => (
                <li
                  key={locationFeature.properties.href}
                  className={clsx(
                    'transition',
                    mapState.selectedLocationUID ===
                      locationFeature.properties.uid && 'ring-4',
                  )}
                >
                  <LocationCard
                    mapLabel={(i + 1).toString()}
                    type={locationFeature.properties.type}
                    href={locationFeature.properties.href}
                    title={locationFeature.properties.title}
                    websiteHref={locationFeature.properties.websiteHref}
                    menuHref={locationFeature.properties.menuHref}
                    phoneNumber={locationFeature.properties.phoneNumber}
                    streetAddress={locationFeature.properties.streetAddress}
                    city={locationFeature.properties.city}
                    state={locationFeature.properties.state}
                    zipCode={locationFeature.properties.zipCode}
                    country={locationFeature.properties.country}
                    distance={distanceFromCurrentPosition(
                      locationFeature.geometry.coordinates,
                    )}
                    loyaltyplantHref={
                      locationFeature.properties.loyaltyplantHref
                    }
                    mondayHours={locationFeature.properties.mondayHours}
                    tuesdayHours={locationFeature.properties.tuesdayHours}
                    wednesdayHours={locationFeature.properties.wednesdayHours}
                    thursdayHours={locationFeature.properties.thursdayHours}
                    fridayHours={locationFeature.properties.fridayHours}
                    saturdayHours={locationFeature.properties.saturdayHours}
                    sundayHours={locationFeature.properties.sundayHours}
                    ubereatsHref={locationFeature.properties.ubereatsHref}
                    doordashHref={locationFeature.properties.doordashHref}
                    postmatesHref={locationFeature.properties.postmatesHref}
                    biteSquadHref={locationFeature.properties.biteSquadHref}
                    grubhubHref={locationFeature.properties.grubhubHref}
                    acceptsGiftCards={
                      locationFeature.properties.acceptsGiftCards
                    }
                    providesOnlineOrdering={
                      locationFeature.properties.providesOnlineOrdering
                    }
                    providesDelivery={
                      locationFeature.properties.providesDelivery
                    }
                    className="h-full"
                  />
                </li>
              ),
            )}
          </ul>
        ) : mapState.isReady ? (
          <div className="grid content-center gap-6 min-h-12rem">
            <Text
              variant="sans-35-40"
              className="font-extrabold text-center text-red-50"
            >
              Sorry!
            </Text>
            <Text variant="sans-18" className="text-center">
              No locations found. Try a different area or change your filters.
            </Text>
          </div>
        ) : (
          <div className="grid content-center gap-7 md:gap-8 min-h-12rem">
            <Text variant="sans-35-40" className="text-center text-gray-60">
              Loading…
            </Text>
          </div>
        )}
      </BoundedBox>
    </>
  )
}

export const mapDataToContext = () => ({
  bg: 'bg-white',
})

export default React.memo(PageBodyLocations)
