/**
 * Root Prismic slices file where Page Body slices are registered for use in the
 * page template file.
 *
 * @see /docs/guide-create-a-slice.md for more details.
 */

import { graphql } from 'gatsby'

import { reshapeSlicesMap } from '../lib/mapSlicesToComponents'

// 1. Import your slice
import * as PageBodyAccordion from './PageBodyAccordion'
import * as PageBodyAnchor from './PageBodyAnchor'
import * as PageBodyCallToActionBoxes from './PageBodyCallToActionBoxes'
import * as PageBodyCallout from './PageBodyCallout'
import * as PageBodyCalloutBox from './PageBodyCalloutBox'
import * as PageBodyCenteredText from './PageBodyCenteredText'
import * as PageBodyCognitoForm from './PageBodyCognitoForm'
import * as PageBodyFavoriteDishes from './PageBodyFavoriteDishes'
import * as PageBodyFeaturedImage from './PageBodyFeaturedImage'
import * as PageBodyFooter from './PageBodyFooter'
import * as PageBodyHeader from './PageBodyHeader'
import * as PageBodyHeroFloatingImage from './PageBodyHeroFloatingImage'
import * as PageBodyHeroImage from './PageBodyHeroImage'
import * as PageBodyHeroMenuItem from './PageBodyHeroMenuItem'
import * as PageBodyHeroNavigation from './PageBodyHeroNavigation'
import * as PageBodyHeroPhrases from './PageBodyHeroPhrases'
import * as PageBodyHighlights from './PageBodyHighlights'
import * as PageBodyImage from './PageBodyImage'
import * as PageBodyLLContent from './PageBodyLLContent'
import * as PageBodyLLHero from './PageBodyLLHero'
import * as PageBodyLLPartners from './PageBodyLLPartners'
import * as PageBodyLLText from './PageBodyLLText'
import * as PageBodyLargePromo from './PageBodyLargePromo'
import * as PageBodyLocationDetails from './PageBodyLocationDetails'
import * as PageBodyLocationStaticMap from './PageBodyLocationStaticMap'
import * as PageBodyLocations from './PageBodyLocations'
import * as PageBodyMenuItems from './PageBodyMenuItems'
import * as PageBodyNutritionFacts from './PageBodyNutritionFacts'
import * as PageBodyPlateLunchDiagram from './PageBodyPlateLunchDiagram'
import * as PageBodyPromoBoxes from './PageBodyPromoBoxes'
import * as PageBodyPromos from './PageBodyPromos'
import * as PageBodyQuote from './PageBodyQuote'
import * as PageBodyText from './PageBodyText'
import * as PageBodyVideos from './PageBodyVideos'
import * as PageBodyFullWidthImage from './PageBodyFullWidthImage'
import * as PageBodyPartners from './PageBodyPartners'

// 2. Add your slice
const slices = {
  PageBodyAccordion,
  PageBodyAnchor,
  PageBodyCallToActionBoxes,
  PageBodyCallout,
  PageBodyCalloutBox,
  PageBodyCenteredText,
  PageBodyCognitoForm,
  PageBodyFavoriteDishes,
  PageBodyFeaturedImage,
  PageBodyFooter,
  PageBodyHeader,
  PageBodyHeroFloatingImage,
  PageBodyHeroImage,
  PageBodyHeroMenuItem,
  PageBodyHeroNavigation,
  PageBodyHeroPhrases,
  PageBodyHighlights,
  PageBodyImage,
  PageBodyLLContent,
  PageBodyLLHero,
  PageBodyLLPartners,
  PageBodyLLText,
  PageBodyLargePromo,
  PageBodyLocationDetails,
  PageBodyLocationStaticMap,
  PageBodyLocations,
  PageBodyMenuItems,
  PageBodyNutritionFacts,
  PageBodyPlateLunchDiagram,
  PageBodyPromoBoxes,
  PageBodyPromos,
  PageBodyQuote,
  PageBodyText,
  PageBodyVideos,
  PageBodyFullWidthImage,
  PageBodyPartners,
}

// 3. Add your slice fragment
export const fragment = graphql`
  fragment SlicesPageBody on PrismicPageBodySlicesType {
    ...PageBodyAccordion
    ...PageBodyAnchor
    ...PageBodyCallToActionBoxes
    ...PageBodyCallout
    ...PageBodyCalloutBox
    ...PageBodyCenteredText
    ...PageBodyCognitoForm
    ...PageBodyFavoriteDishes
    ...PageBodyFeaturedImage
    ...PageBodyHeroFloatingImage
    ...PageBodyHeroImage
    ...PageBodyHeroMenuItem
    ...PageBodyHeroNavigation
    ...PageBodyHeroPhrases
    ...PageBodyHighlights
    ...PageBodyImage
    ...PageBodyLLContent
    ...PageBodyLLHero
    ...PageBodyLLPartners
    ...PageBodyLLText
    ...PageBodyLargePromo
    ...PageBodyMenuItems
    ...PageBodyNutritionFacts
    ...PageBodyPlateLunchDiagram
    ...PageBodyPromoBoxes
    ...PageBodyPromos
    ...PageBodyQuote
    ...PageBodyText
    ...PageBodyVideos
    ...PageBodyFullWidthImage
    ...PageBodyPartners

    # The following slices do not have fragments:
    #...PageBodyFooter
    #...PageBodyHeader
    #...PageBodyLocationDetails
    #...PageBodyLocationStaticMap
    #...PageBodyLocations
  }
`

export const slicesMap = reshapeSlicesMap(slices)
